import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'
import { useSnackbar } from 'notistack5'
import menu2Fill from '@iconify/icons-eva/menu-2-fill'
import { alpha, styled } from '@material-ui/core/styles'
import { Box, Stack, AppBar, Toolbar, IconButton, Typography, Container } from '@material-ui/core'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { MHidden } from '../../components/@material-extend'
//
import MenuIcon from '@mui/icons-material/Menu';
// import NotificationsPopover from './NotificationsPopover'
import storage from 'utils/storage'
import { SocketContext } from 'utils/context/socket'
import Logo from 'components/Logo'
// import {UsersContext} from 'utils/context/users'
const DRAWER_WIDTH = 0
const APPBAR_MOBILE = 40
const APPBAR_DESKTOP = 92




const DashboardNavbar = ({ open, onOpenSidebar, onOpenAdminSidebar, _data }) => {
  const navigation = useNavigate()
  const location = useLocation()
  const socket = useContext(SocketContext)
  const { enqueueSnackbar } = useSnackbar()
  // const {users, setUsers} = useContext(UsersContext)
  const [data, setData] = useState({
    users: [],
    employee: [],
    loading: false,
    online: 0,
  })

  const [isHeaderVisible, setIsHeaderVisible] = useState(false);
  const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    backgroundImage: data.users.role >= 3 || data.users.role === 1 ? 'none' : (isHeaderVisible ? 'none' : `url('/favicon/BACKGROUND.svg')`), // Use the image URL within the backgroundImage property
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    position: 'fixed',
    color: 'white', // Text color
    height: '100px',
    width: '100%',
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
      minHeight: APPBAR_DESKTOP,
      padding: theme.spacing(0, 5),
    },
  }))

  const load = async () => {
    setData((p) => ({ ...p, loading: true }))
    const local_user = await storage.getUser()
    if (!local_user) return;
    const user = JSON.parse(local_user)
    let { _id, role } = user
    if (role === 1 || role === 3) {
      socket.emit('get_users', { _id })
    }
    return setData((p) => ({ ...p, users: user, loading: false }))
  }

  const handleNavigateDashboard = () => {
    onOpenSidebar()
    navigation('/dashboard/app')
  }

  useEffect(() => {
    load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

/*  useEffect(() => {
    socket.on('notification', async (notif) => {
      const local_user = await storage.getUser()
      if (!local_user) return;
      const user = JSON.parse(local_user)
      if (user.role === 1 || user.role === 4) {
        let { _id } = user
        socket.emit('get_users', { _id })
        enqueueSnackbar(notif?.description, {
          variant: 'info',
        })
      }
    })

    socket.on('receive_status', async (notif) => {
      const local_user = await storage.getUser()
      if (!local_user) return;
      const user = JSON.parse(local_user)
      if (user.role === 1 || user.role === 4) {
        let { _id } = user
        socket.emit('get_users', { _id })
        enqueueSnackbar(`${notif?.user} - ${notif?.status}`, {
          variant: 'info',
        })
      }
    })

    socket.on('users', async (users) => {
      if (!users) return console.log('error fetching users')

      const local_user = await storage.getUser()
      if (!local_user) return console.log('error current user')

      const index = users.findIndex((user) => user.uuid === user._id)
      if (index !== -1) {
        return users.splice(index, 1)[0]
      }

      return setData((p) => ({ ...p, online: users.length }))
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, enqueueSnackbar])*/
  return (
      <ToolbarStyle sx={{borderRadius: open ? '0 0 0 50px' : '0 0 50px 50px'}}>
        {location.pathname === '/dashboard/app/profile' && (
          <IconButton
            onClick={() => handleNavigateDashboard()}
            sx={{
              color: 'text.primary',
              position: 'absolute',
              zIndex: 1000,
            }}
          >
            <ChevronLeftIcon sx={{color: 'white'}} fontSize='large' />
          </IconButton>
        )}
          <>
            {data.users.role === 0
              ? 
                  <>
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'center',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                  }}>
                      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', pt: 4}}>
                        <img src="/favicon/header-logo.png" alt="Dashboard Logo" style={{width: '65%', height: '65%'}} />
                        <Typography
                          variant="p" 
                          sx={{
                            fontSize: '0.80rem',
                            color: 'white',
                            pb: 5
                          }}
                        >
                          App Version: {process.env.REACT_APP_VERSION}
                        </Typography>
                      </Box>
                  </Box>
                  </>
              : 
                ''
            }
          </>
        {data.users.role === 1 || data.users.role >= 3 ? (
          <MHidden width="lgUp">
            <IconButton onClick={onOpenAdminSidebar} sx={{ mr: 1, color: 'text.primary' }}>
              <Icon icon={menu2Fill} /> 
              <h5 style={{ color: '#727272' }}>
                Menu
              </h5>
            </IconButton>
          </MHidden>
        ) : (
          ''
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {data.users.role === 1 || data.users.role >= 3 ? (
            <>
              <h5 style={{ color: '#727272' }}>
                {data.online} Online {data.online && data.online > 0 ? 'Employees' : 'Employee'}
              </h5>
              {/* <NotificationsPopover /> */}
            </>
          ) : (
            ''
          )}

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={onOpenSidebar}
            sx={{
              '&:hover': {
                backgroundColor: 'rgba(33, 150, 243, 0.30)'
              },
              mr: 1
            }}
          >
            <MenuIcon sx={{ color: '#2196f3', fontSize: 30 }} />
          </IconButton>
        </Stack>
      </ToolbarStyle>
  )
}

export default DashboardNavbar

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
}
