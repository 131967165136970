import React, {useEffect, useState, useCallback} from 'react'
import userAPI from 'utils/api/users'
import { UserListHead, UserListToolbar } from 'components/_dashboard/user'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import UserBreaklistModal from './modal'


const UserBreaklist = ({onEditValues = () => {}, onClose = () => {}, employeeId = '', breaklistId = '', from = '', to = '', name = ''}) => { 
	console.log(onClose)
	let [breaklist, setBreaklist] = useState([])

	useEffect(async () => {
		let mounted = true

		if(mounted){
			//fetch api here
			loadUsersBreaklist()
		}

		return () => {
			mounted = false
		}
	}, []) 

	let loadUsersBreaklist = useCallback( async () => {

		const data = {
			id: employeeId, 
			from: from, 
			to: to
		}

		

		try {
	      const result = await userAPI.get_schedule_with_range(data);

	      let usersSchedule = result.data
	      console.log(usersSchedule)

	      setBreaklist(usersSchedule)

	    } catch (error) {

	      console.error('Error fetching schedule:', error);

	    } finally {
	    } 
	}, [employeeId, from, to]) 

	


	return <ClickAwayListener onClickAway = {onClose}><UserBreaklistModal name = {name} breaklistId = {breaklistId} onClose = {onClose} breaklist = {breaklist} onSave={loadUsersBreaklist}/></ClickAwayListener>
}

export default UserBreaklist





