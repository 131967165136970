import React from 'react'
import { Icon } from '@iconify/react'
import { useRef, useState } from 'react'
// import editFill from '@iconify/icons-eva/edit-fill'
import archiveOutline from '@iconify/icons-eva/archive-outline'
import clinicalNotesOutline from '@iconify/icons-material-symbols/clinical-notes-outline'
import { Link as RouterLink } from 'react-router-dom'
import trash2Outline from '@iconify/icons-eva/trash-2-outline'
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill'
import fileDoneOutlined from '@iconify/icons-ant-design/file-done-outlined';
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TextField,
  Label,
  Box,
  FormControl,
  Select,
  InputLabel,
  Paper,
  Grid,
  FormControlLabel,
  FormGroup,

} from '@material-ui/core'
import { makeStyles } from "@material-ui/styles";


import { useSnackbar } from 'notistack5'

//api

import useUser from 'utils/api/users'
import storage from 'utils/storage'

// ----------------------------------------------------------------------

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function UserMoreMenu({ id, action, name, tardi, ot, nightdiffval, rd }) {
  const ref = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [open, setOpen] = useState(false)
  const [hoursTardy, setHoursTardy] = useState(tardi)
  const [overtime, setOvertime] = useState(ot)
  const [nightdiff, setNightDiff] = useState(nightdiffval)
  const [restday, setRestDay] = useState(rd)
  const [openArchive, setOpenArchive] = useState(false)
  const [users, setUsers] = useState([])
  const { enqueueSnackbar } = useSnackbar()

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary
    }
  }));
  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true)
    setIsOpen(false)
  }
  const handleClose = () => {
    setIsOpen(false)
    setOpen(false)
  }
  const handleArchive = async () => {
    const data = {
      "id": id,
      "hoursTardy": hoursTardy,
      "overtime": overtime,
      "nightdiff": nightdiff,
      "restday": restday
    }
    action(data)
    setOpen(false)
    setIsOpen(false)

  }
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={archiveOutline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Update"
            onClick={handleClickOpen}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>
      </Menu>

      <div>
        
       <Dialog open={open} onClose={handleClose}>
         <DialogTitle>{name}</DialogTitle>
         <DialogContent>
           <TextField
             error
             autoFocus
             margin="dense"
             id="name"
             label="Hours Tardy (Minutes) update generated breaklist"
             type="text"
             fullWidth
             value={hoursTardy}
             variant="standard"
             onChange={(e) => setHoursTardy(e.target.value)}
           />
           <TextField
             error
             margin="dense"
             id="name"
             label="Overtime (Hours)"
             type="text"
             fullWidth
             value={overtime}
             variant="standard"
             onChange={(e) => setOvertime(e.target.value)}
           />
           <TextField
             error
             margin="dense"
             id="name"
             label="Night Diff (Hours)"
             type="text"
             fullWidth
             value={nightdiff}
             variant="standard"
             onChange={(e) => setNightDiff(e.target.value)}
           />
           <TextField
             error
             margin="dense"
             id="name"
             label="Rest Day (Hours)"
             type="number"
             fullWidth
             value={restday}
             variant="standard"
             onChange={(e) => setRestDay(e.target.value)}
           />
         </DialogContent>
         <DialogActions>
           <Button onClick={handleClose}>Cancel</Button>
           <Button onClick={handleArchive}>Upload</Button>
         </DialogActions>
       </Dialog>
      </div>

    </>
  )
}
