import React, {useState, memo, useCallback, useEffect, useRef} from 'react'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'

const Editable = memo(({id = '', text = 'No remarks yet.', onEditText = () => {}}) => {
  let [editable, setEditable] = useState(false)
  let [editableText, setEditableText] = useState(text)
  let textEditor = useRef()

  //for computing diff of previous vs new value
  let value = useRef(text)


  let saveText = useCallback(async () => {

    let newNumber = String(editableText)?.trim().length  === 0 ? 0 : Number(editableText)
    let diff = newNumber === 0 ? 0 -  Number(text) : Number(editableText) - Number(value.current)

    value.current = newNumber 

    console.log(newNumber, diff)
    onEditText(newNumber, diff)
    setEditableText(String(newNumber))
    /*
    if(String(editableText)?.trim().length  || 0 >  0) {

      let prevValue = value.current
      value.current = editableText

      //pass diff as second argument
      onEditText(editableText, Number(editableText) - prevValue)
    }
    else {
      setEditableText(text)
    }*/
  }, [editableText])

  let onEditing = useCallback((e) => {
  	setEditableText(textEditor.current.value)
    if (e.nativeEvent.which === 13 || e.nativeEvent.which === 27) {
      saveText()
      turnOffEditable()
    }
  }, [])

  let turnOnEditable = useCallback(() => {
    console.log('setting editable')
    setEditable(true)
  }, [])

  let turnOffEditable = useCallback(() => {
    console.log('not setting editable')
    setEditable(false)
  }, [])



  return (
    <>
      {editable ? (
        <ClickAwayListener
          onClickAway={() => {
            saveText()
            turnOffEditable()
          }}
        >
        	<input 
            onChange = {onEditing} 
            type = 'number' 
            min = "0"
            value = {Number(editableText).toString()} 
            ref = {textEditor} 
            onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
          />
        </ClickAwayListener>
      ) : (
        <div ref={textEditor} onClick={turnOnEditable} contentEditable={editable} style = {{ backgroundColor: "#dedffa"}}>
          {editableText}
        </div>
      )}
    </>
  )
})

export default Editable