import { useEffect, useState, useContext } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
// material
import jwt_decode from 'jwt-decode'
import { alpha, styled, useTheme } from '@material-ui/core/styles'
//
import DashboardNavbar from './DashboardNavbar'
import DashboardSidebar from './DashboardSidebar'
import storage from 'utils/storage'
import user_api from 'utils/api/users'

import { SocketContext } from 'utils/context/socket'
import { MainContext } from 'utils/context/main'
import NotificationDialog from 'components/firebase/NotificationDialog'
import NotificationComponent from 'components/firebase/Notification'
import MuiAppBar from '@mui/material/AppBar';
import { Drawer, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Box, Typography, Avatar } from '@material-ui/core';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AccountPopover from './AccountPopover'
import NewFeatureDialog from 'components/newFeatureDialog'
import { Icon } from '@iconify/react'
/*import {useFirebase} from 'utils/context/firebase'*/
// import {UsersContext} from 'utils/context/users'

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 92
const drawerWidth = 300;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
})

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100vh',
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(10),
  width: '100%',
  maxWidth: '100%',
  [theme.breakpoints.up('lg')]: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2), 
    paddingRight: theme.spacing(2),
  },
}))

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    marginTop: 3,
    padding: theme.spacing(1),
    width: '100%',
    maxWidth: '100%',
    overflowX: 'hidden',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      marginRight: `-100%`,
      width: '100%'
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: -drawerWidth,
      width: '100%'
    },
    ...(open && {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
      [theme.breakpoints.down('sm')]: {
        width: `calc(100% - 100%)`
      },
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`
      }
    }),
    /**
     * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
     * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
     * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
     * proper interaction with the underlying content.
     */
    position: 'relative',
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)',
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  ...(open && {
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - 100%)`,
    },
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      marginRight: `100%`,
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: drawerWidth,
    },
  }),
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const theme = useTheme();
  const socket = useContext(SocketContext)
  const { setName, setRoom } = useContext(MainContext)
  const [openUserDrawer, setOpenUserDrawer] = useState(false)
  const [openAdminDrawer, setOpenAdminDrawer] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [notifyToggle, setNotifyToggle] = useState(storage.getScheduleNotify() ?? false)
/*  const {getFcmToken} = useFirebase()*/
/*  const [fCMToken, setFCMToken] = useState(storage.getFCMToken() ?? null);
  const [fCMFailCount, setFCMFailCount] = useState(0);*/
  const [componentMounted, setComponentMounted] = useState(false);
  const [data, setData] = useState({
    users: [],
    online: 0,
    employees: [],
  })
  const [user, setUser] = useState(null)

/*  useEffect( async () => {
    const fetchAndStoreFcmToken = async () => {
      try {
        const user = JSON.parse(await storage.getUser())
        console.log('kuha na: ', user)
        if (!fCMToken) {
          console.log("FCM token is not available, fetching a new one");
          const newFcmToken = await getFcmToken();
  
          if (newFcmToken) {
            storage.storeFCM(newFcmToken);
            setFCMToken(newFcmToken);
            if (user) {
              const currentUserFCMToken = await user_api.get_active_fcm_token(user._id);
              const fcmTokensArray = currentUserFCMToken.data.fcmTokens.map(tokenObj => tokenObj.fcmToken);
              if(!currentUserFCMToken || !fcmTokensArray.includes(fCMToken)) {
                await user_api.subscribe_fcm_token(user._id, fCMToken, 'pwa');
                console.log("Subscribed FCM token for user:", user._id);
              }else{
                console.log("Failed to subscribe user token", user._id);
              }
            }
          } else {
            console.log("Failed to fetch new FCM token");
            return;
          }
        } else {
          console.log("FCM token already exists:", fCMToken);
          if (!storage.getFCMToken()) storage.storeFCM(fCMToken);
          if (user) {
            const currentUserFCMToken = await user_api.get_active_fcm_token(user._id);
            const fcmTokensArray = currentUserFCMToken.data.fcmTokens.map(tokenObj => tokenObj.fcmToken);
            if(!currentUserFCMToken || !fcmTokensArray.includes(fCMToken)) {
              await user_api.subscribe_fcm_token(user._id, fCMToken, 'pwa');
              console.log("Subscribed FCM token for user:", user._id);
            }else{
              console.log("Failed to subscribe user token", user._id);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching or storing FCM token:", error);
      }
    };

    if (notifyToggle === true) {
      const intervalId = setInterval(async () => {
        const existingToken = storage.getFCMToken()
        
        if (existingToken) {
          clearInterval(intervalId); // Stop the interval if token is already in local storage
          return;
        }
  
        if (fCMFailCount > 5) {
          clearInterval(intervalId); // Stop the interval if token is already in local storage
          return;
        }
        
        fetchAndStoreFcmToken();
        setFCMFailCount((prev) => prev + 1);
      }, 15000); // Fetch every 5 seconds
      fetchAndStoreFcmToken();
    }
  }, [fCMToken, notifyToggle])*/

  useEffect(() => {
    const load = async () => {
      const local_user = await storage.getUser()

      if (!local_user) return navigate('/login')

      const user = JSON.parse(local_user)
      let name,
        _id,
        uuid = undefined

      if (user.role === 1 || user.role >= 3) {
        name = user.displayName
        uuid = _id = user._id
        socket.emit('connected', { name, uuid, _id }, (error) => {
          if (error) {
            console.log(error)
          }
        })
      } else {
        name = user.displayName
        _id = user.sid
        uuid = user._id
      }

      if (!name || !uuid || !_id) return
      setName(name)
      setRoom(uuid)
      socket.emit('connected', { name, uuid, _id }, (error) => {
        if (error) {
          console.log(error)
        }
      })
      return setData((p) => ({ ...p, users: user }))
    }

    const check_login = async () => {
      const token = await storage.getToken()
      const current_date = new Date()

      if (!token) {
        await storage.remove()
        return
      }

      if (jwt_decode(token)['exp'] * 1000 < current_date.getTime()) {
        await storage.remove()
        return navigate('/login')
      }

      const result = await user_api.get_user(jwt_decode(token)['id'])
      if (!result.ok) {
        await storage.remove()
        return navigate('/login')
      }
      await storage.storeUser(result.data)
      setUser(result.data)
      if (result.data.isArchived) {
        return alert('Your account is disabled')
        window.location.href = '/login'
      }
      if ((result.data.role === 1 || result.data.role >= 3) && !result.data.isOnBoarded) return navigate('/store/onboard')
      if (result.data.role === 0 && !result.data.isOnBoarded) return navigate(`${result.data.store_id}/onboard`)

      if (result.data.role === 1 || result.data.role >= 3) return navigate('/stores')

    }

    check_login()
    load()
    if (!storage.getScheduleNotify()) setDialogOpen(true); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openUserDrawer])

  useEffect(() => {
    if(openUserDrawer) {
      handleDrawerClose()
    }
  }, [])

  useEffect(() => {
    // Set componentMounted to true after initial render
    setComponentMounted(true);
    
    // Reset componentMounted when location changes
    return () => {
      setComponentMounted(false);
    };
  }, [location.pathname]);

  useEffect(() => {
    // Function to handle back button press
    const handleBackButton = (event) => {
      // Prevent default behavior (app exit)
      if (openUserDrawer || openAdminDrawer) {
        event.preventDefault();
        
        // Close the drawer that is open
        if (openUserDrawer) {
          handleDrawerClose();
        } else if (openAdminDrawer) {
          handleAdminDrawerClose();
        }
      }
      // If drawer is not open, default back button behavior applies (exit app)
    };

    // Add event listener for back button for Android
    document.addEventListener('backbutton', handleBackButton);

    // For browsers with History API (this only works in browser environments, not native)
    const handlePopState = (event) => {
      if (openUserDrawer || openAdminDrawer) {
        event.preventDefault();
        if (openUserDrawer) {
          handleDrawerClose();
        } else if (openAdminDrawer) {
          handleAdminDrawerClose();
        }
        // Push a new state to prevent navigating back
        window.history.pushState(null, document.title, window.location.href);
        return;
      }
    };

    window.addEventListener('popstate', handlePopState);
    
    // Push an initial state
    window.history.pushState(null, document.title, window.location.href);

    // Clean up event listeners
    return () => {
      document.removeEventListener('backbutton', handleBackButton);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [openUserDrawer, openAdminDrawer]);

  const handleCloseDialog = () => {
    setDialogOpen(false);
    storage.setScheduleNotify(notifyToggle)
  };

  const handleNotifyToggle = (value) => {
    setNotifyToggle(value)
  }

  const handleDrawerOpen = () => {
    setOpenUserDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenUserDrawer(false);
  };

  const handleAdminDrawerOpen = () => {
    setOpenAdminDrawer(true);
  };

  const handleAdminDrawerClose = () => {
    setOpenAdminDrawer(false);
  };



  return (
    <>
      <RootStyle>
        <NotificationComponent />
        <NewFeatureDialog />
        <AppBar position="fixed" open={openUserDrawer}>
          <DashboardNavbar open={openUserDrawer} onOpenSidebar={handleDrawerOpen} onOpenAdminSidebar={handleAdminDrawerOpen} _data={data} />
        </AppBar>
        <NotificationDialog open={dialogOpen} onClose={handleCloseDialog} toggle={notifyToggle} onToggle={handleNotifyToggle} />  
        {data.users.role && (JSON.parse(data.users.role) === 1 || JSON.parse(data.users.role) >= 3) ? (
          <DashboardSidebar isOpenSidebar={openAdminDrawer} account={data.users} onCloseSidebar={handleAdminDrawerClose} />
        ) : (
          ''
        )}
         <Main open={openUserDrawer}>
         <DrawerHeader />
          <MainStyle style={{ minHeight: componentMounted ? '100vh' : '200vh' }}>
            {/* <Detector
              render={({online}) =>
                !online ? (
                  <>
                    <Box
                      component="img"
                      src="/static/illustrations/offline.gif"
                      sx={{height: 300, mx: 'auto', my: {xs: 0, sm: 0}}}
                    />
                    <Typography variant="h4" sx={{textAlign: 'center'}}>
                      No Internet Connection
                    </Typography>
                  </>
                ) : (
                  <Outlet />
                )
              }
            /> */}
            <Outlet />
          </MainStyle>
        </Main>
        <Drawer
          sx={{
            width: drawerWidth,
            [theme.breakpoints.down('sm')]: {
              width: `100%`,
            },
            [theme.breakpoints.up('sm')]: {
              width: drawerWidth,
            },
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              [theme.breakpoints.down('sm')]: {
                width: `100%`,
              },
              [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
              },
            },
          }}
          variant="persistent"
          anchor="right"
          open={openUserDrawer}
        >
        <DrawerHeader sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          height: 150,
          background: `url('/favicon/BACKGROUND.svg') no-repeat center right`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          color: 'white',
          overflow: 'hidden',
          px: 2,
          borderRadius: '0 0 30px 30px'
        }}>
           <IconButton 
              onClick={handleDrawerClose} 
              sx={{
                color: 'white',
                size: 60,
                position: 'absolute',
                left: 8
              }}
            >
              {theme.direction === 'rtl' ? 
                <ChevronLeftIcon sx={{color: 'white', fontSize: 30}} /> : 
                <ChevronRightIcon sx={{color: 'white', fontSize: 30}} />
              }
            </IconButton>
            <Box sx={{
              display: 'flex', 
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1,
              pl: 2
            }}>
              <Avatar
                src={user?.image || '/static/mock-images/avatars/avatar_default.jpg'}
                sx={{ 
                  width: 60,
                  height: 60,
                  border: '2px solid white'
                }}
              />
              
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="subtitle1" noWrap>
                  {user?.displayName}
                </Typography>
                <Typography variant="body2" noWrap>
                  {user?.email}
                </Typography>
              </Box>
            </Box>
        </DrawerHeader>
        <AccountPopover user={data.users} onNavigate={handleDrawerClose} /> 
      </Drawer>
      </RootStyle>
    </>
  )
}
