import * as Yup from 'yup'
import { useState, forwardRef, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useFormik, Form, FormikProvider } from 'formik'
import SvgIcon from '@material-ui/core/SvgIcon';
import { Icon } from '@iconify/react'
import eyeFill from '@iconify/icons-eva/eye-fill'
import eyeOffFill from '@iconify/icons-eva/eye-off-fill'
import userLight from '@iconify/icons-ph/user-light';
// material
import {
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Modal,
  Backdrop,
  Fade,
  Box,
  Grid,
  Typography,
  Button,
  Dialog,
  Slide,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  CircularProgress
} from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { LoadingButton } from '@material-ui/lab'
import { Loading } from 'components/timeButton'
import { HighlightOff as HighlightOffIcon } from '@mui/icons-material'
import SwitchedIcon from 'assets/svg/loginSwitched.png'
import EmployeeVisualAid from 'assets/svg/stk-employee-reg-visual-aid.png'
import storage from 'utils/storage'
import userApi from 'utils/api/users'
import useAuth from 'utils/api/auth'
import Bugsnag from '@bugsnag/js'
import QrReader from 'react-qr-reader'
import ChangePass from 'components/account/ChangePass'
// ----------------------------------------------------------------------

export default function LoginForm({ handleSwitchForm }) {
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [openCreateAccount, setOpenCreateAccount] = useState(false)
  const [openPasswordReset, setOpenPasswordReset] = useState(false)
  const [openQR, setOpenQR] = useState(false)
  const [scanMessage, setScanMessage] = useState('No data found')
  const [initialChoice, setInitialChoice] = useState(false); // Tracks initial choice modal visibility
  const [isStoreManager, setIsStoreManager] = useState(false); // Tracks if user chose "Store Manager"

  // useEffect(() => {
  //   setInitialChoice(true); // Opens the initial user choice dialog on component load
  // }, []);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address'),
    password: Yup.string().required('Password is required'),
  })

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      setLoading(true)
      if (!values.email || !values.password) return setLoading(false)

      const result = await useAuth.sign_in_email(values.email, values.password)
      if (!result.ok) {
        Bugsnag.notify(result)
        alert('Invalid username or password')
        return setLoading(false)
      }
      let { data } = result
      if (data.role === 0) {
        data.sid = data.store_id
      }
      if (!JSON.parse(data.isOnBoarded)) {
        setLoading(false)
        return navigate(`/${data.sid}/onboard`)
      }
      resetForm()
      const isWebView = navigator.userAgent.includes('wv') || // Android WebView
      navigator.userAgent.includes('Mobile/') && !navigator.userAgent.includes('Safari'); // iOS WebView
      if (data.role === 1 || data.role >= 3) {
        if (isWebView) {
          alert("Important Notice: Store manager accounts require browser access for all features. Please open sparkletimekeeping.com in Chrome, Safari, or another web browser.");
          return navigate(`/login`, { replace: true });
        }
      }
      await storage.storeUser(data)
      await storage.storeToken(data.token)
      if (data.role === 1 || data.role >= 3) { 
        return navigate(`/stores/app`, { replace: true })
      }
      
      return navigate(`/dashboard/app`, { replace: true })
      setLoading(false)

    },
  })

  const { errors, touched, values, resetForm, handleSubmit, getFieldProps } = formik
  const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
  })

  const handleUserChoice = (choice) => {
    if (choice === "Employee") {
      setIsStoreManager(false);
      setOpenCreateAccount(true);
    } else if (choice === "Client Manager") {
      setIsStoreManager(true);
      setOpenCreateAccount(true);
    }
    setInitialChoice(false);
  };

  const handleShowPassword = () => {
    setShowPassword((show) => !show)
  }

  const handleClosePassword = () => {
    setOpenPasswordReset(false)
  }

/*  useEffect(() => {
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;

    if (connection) {
      const { type, effectiveType, downlink } = connection;
      if(downlink < 0.5) {
        alert("Slow internet connection please wait")
      }
    }
  }, []);
  useEffect(() => {

    const interval = setInterval(() => {

      if (!navigator.onLine) {
        alert('You are offline. Please check your internet connection.');
      }

    }, 1000);

    return () => clearInterval(interval);
  }, [])*/

  const handleScan = async (res) => {
    if (res) {
      window.location.href = res;
    } else {
      alert("No link found in the response.");
    }
  };

  const handleCloseQR= async () => {
    setOpenQR(false)
  }

  const handleOpenCreateAccount = async () => {
    setInitialChoice(true)
    // setOpenCreateAccount(true)
  }

  const handleCloseCreateAccount= async () => {
    setOpenCreateAccount(false)
  }

  const handleError = (err) => {
    alert(err)
  }

  const handleForgotPassword = () => {
    setOpenPasswordReset(true);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Box sx={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              autoFocus
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              InputProps={{
                sx: {
                  borderRadius: 28,
                  backgroundColor: '#F6F6F6',
                  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.30)', // Adjust the shadow values
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton edge="center">
                      <AccountCircleOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                // endAdornment: (
                //   <InputAdornment position="end">
                //       <div
                //         style={{
                //           width: '40px',
                //           height: '40px',
                //           border: '3px solid #1F98E8',
                //           borderRadius: '50%',
                //           overflow: 'hidden',
                //         }}
                //         onClick={() => handleSwitchForm(true)}
                //       >
                //         <img
                //           width="100%"
                //           height="100%"
                //           style={{ filter: 'invert(60%) sepia(100%) saturate(300%) hue-rotate(180deg)' }}
                //           src={SwitchedIcon}
                //           alt="custom-icon"
                //         />
                //       </div>
                //   </InputAdornment>
                // )
              }}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              {...getFieldProps('password')}
              InputProps={{
                sx: {
                  borderRadius: 28,
                  backgroundColor: '#F6F6F6',
                  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.30)', // Adjust the shadow values
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton position='start'>
                      <LockOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <FormControlLabel
              control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
              label="Remember me"
              sx={{ color: '#1F98E8' }}
            />

            <Link 
              onClick={handleForgotPassword} 
              variant="subtitle2" 
              to="#" 
              style={{ color: '#1F98E8' }}
            >
              Forgot password?
            </Link>
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isLoading}
            loadingIndicator={<CircularProgress color="primary" />}
            style={{
              borderRadius: 28,
              color: 'white',
              backgroundColor: '#1F98E8',
              boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.40)',
            }}>
            Login
          </LoadingButton>
          <Typography
            onClick={() => handleSwitchForm()} 
            variant='body1' 
            color='#1F98E8' 
            sx={{textAlign:'center', mt:'1rem', cursor: 'pointer'}}> 
            Login using mobile number 
          </Typography>
          <Typography
            onClick={handleOpenCreateAccount} 
            variant='body1' 
            color='#1F98E8' 
            sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', mt: 1 }}
          > 
            {/*<QrCodeScannerIcon />*/}
            Create an account
          </Typography>
        </Box>
      </Form>
      
      <ChangePass 
        open={openPasswordReset}
        onClose={handleClosePassword}
      />
      
      <Dialog open={initialChoice} onClose={() => setInitialChoice(false)}>
  <DialogTitle>Choose User Type</DialogTitle>
  <DialogContent>
    <Typography variant="body2">Are you an employee or a Client?</Typography>
    <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
      <Button variant="contained" onClick={() => handleUserChoice("Employee")}>Employee</Button>
      <Button variant="outlined" onClick={() => handleUserChoice("Client Manager")}>Client Manager</Button>
    </Stack>
  </DialogContent>
</Dialog>


<Dialog open={openCreateAccount} onClose={handleCloseCreateAccount} maxWidth="xs" fullWidth>
  <DialogTitle sx={{ textAlign: 'center', pb: 0 }}>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
      <img src="/favicon/logo.jpg" alt="Sparkle Timekeeping" style={{ width: 100 }} />
      <Typography variant="h6" fontWeight="bold" sx={{ ml: 2 }}>SPARKLE TIMEKEEPING</Typography>
    </Box>
  </DialogTitle>

  <DialogContent sx={{ textAlign: 'center', px: 5, py: 3 }}>
    <Box sx={{ backgroundColor: '#f8f9fa', borderRadius: 2, p: 3, boxShadow: 1 }}>
      {isStoreManager ? (
        <>
          <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>Create Client Account</Typography>
          <Typography variant="body2" sx={{ mb: 3 }}>Proceed to create a new client account.</Typography>
          <Button variant="contained" color="primary" size="large" 
          onClick={() => window.location.href = '/store/create'}>Create Client Account</Button>
        </>
      ) : (
        <>
          <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>Create Employee Account</Typography>
          <Typography variant="body2" sx={{ mb: 3 }}>Scan client QR code to proceed</Typography>
          <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={EmployeeVisualAid} alt="Visual Aid" style={{ width: 150, marginBottom: '1rem' }} />
            <br />
            <br />
            <img src="https://media.istockphoto.com/id/1358621997/vector/qr-code-smartphone-scanner-linear-icon-vector-illustration.jpg?s=612x612&w=0&k=20&c=ePiWZHIbseW9GwmM498rRKC_Dvk8IsKv41nqnC8iZhQ=" alt="QR code" 
            style={{ width: 150, 
              marginBottom: '1rem', 
              borderRadius: 2, 
              boxShadow: 1, 
              border: '1px solid #f8f9fa', 
              padding: 2, 
              backgroundColor: '#fff'
  
             }} />
          </Box>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => setOpenQR(true)}
            sx={{
              textTransform: 'none',
              px: 5,
              animation: 'pulse 1s infinite', // Add pulse animation
              '@keyframes pulse': { '0%': { transform: 'scale(1)' }, '50%': { transform: 'scale(1.05)' }, '100%': { transform: 'scale(1)' } },
            }}
          >
            Scan Now
          </Button>
        </>
      )}
    </Box>
  </DialogContent>

  <DialogActions sx={{ justifyContent: 'center', flexDirection: 'column', py: 2 }}>

      {/* <Typography variant="body2" sx={{ mb: 2 }}>
        Already have an account?{' '}
        <Button color="primary" sx={{ textTransform: 'none', p: 0 }} 
        onClick={handleCloseCreateAccount}>
          Sign in here
        </Button>
      </Typography> */}
    
    <Button onClick={handleCloseCreateAccount} variant="outlined" color="error" sx={{ mt: 2 }}>Close</Button>
  </DialogActions>
</Dialog>
      <Dialog open={openQR} onClose={handleCloseQR}>
        <DialogTitle>Scan store QR code.
          <IconButton
            aria-label="close"
            onClick={handleCloseQR}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle >
        <DialogContent>
            <>
              <QrReader
                delay={3000}
                onError={handleError}
                onScan={handleScan}
                // chooseDeviceId={()=>selected}
                style={{ width: '250px', heigth: '200px' }}
              // className={'qrScanner'}
              />
              {/*<DialogTitle style={{ display: "flex", justifyContent: "center" }}><a href="https://www.sparkletimekeeping.com/store/create">Register store account</a></DialogTitle>*/}
            </>
        </DialogContent>
      </Dialog>
    </FormikProvider>


  )
}
