import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  IconButton,
  InputAdornment
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import user_api from 'utils/api/users';

export default function ChangePass({ open, onClose, email }) {
  const [isLoading, setLoading] = useState(false);
  const [openVerificationDialog, setOpenVerificationDialog] = useState(false);
  const [openNewPasswordDialog, setOpenNewPasswordDialog] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [emailInput, setEmailInput] = useState('');

  const handlePasswordChange = async () => {
    setLoading(true);
    const data = {
      "email": email || emailInput,
      "type": "change_password"
    };
    const result = await user_api.get_verification_code(data);
    if (result.data.success) {
      alert("Please check verification code sent to your email address");
      setOpenVerificationDialog(true);
      onClose(); // Close the initial dialog
    } else {
      alert(result.data.msg);
    }
    setLoading(false);
  };

  const handleVerificationSubmit = async () => {
    setLoading(true);
    const data = {
      "email": email,
      "token": verificationCode
    };
    const result = await user_api.set_verification_code(data);
    if (result.data.success) {
      setOpenNewPasswordDialog(true);
      setOpenVerificationDialog(false);
    } else {
      alert(result.data.msg);
    }
    setLoading(false);
  };

  const handleNewPasswordSubmit = async () => {
    setLoading(true);
    if (!newPassword) {
      alert("Please input new password");
    } else {
      const data = {
        "email": email,
        "password": newPassword
      };
      const result = await user_api.set_new_password(data);
      if (result.status === 200) {
        alert("Password successfully updated");
        handleClose();
      } else {
        alert("Something went wrong please try again later");
      }
    }
    setLoading(false);
  };

  const handleClose = () => {
    setOpenVerificationDialog(false);
    setOpenNewPasswordDialog(false);
    setVerificationCode('');
    setNewPassword('');
    onClose();
  };

  return (
    <>
      {/* Initial Password Change Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Change Password
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
        { !email ? (
            <>
            <DialogContentText>
                Enter registered email address you used to sign-up.
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Email Address"
                type="email"
                fullWidth
                value={emailInput}
                variant="standard"
                onChange={(e) => setEmailInput(e.target.value)}
            />
            </>
        ) : (
          <>
          <DialogContentText>
            We will send a verification code to your email address ({email}).
            Would you like to proceed?
          </DialogContentText>
          </>
        )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handlePasswordChange} disabled={isLoading}>
            {isLoading ? 'Sending...' : 'Send Code'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Verification Code Dialog */}
      <Dialog open={openVerificationDialog} onClose={handleClose}>
        <DialogTitle>
          Enter Verification Code
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the verification code sent to your email.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Verification Code"
            type="text"
            fullWidth
            variant="standard"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleVerificationSubmit} disabled={isLoading}>
            {isLoading ? 'Verifying...' : 'Verify'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* New Password Dialog */}
      <Dialog open={openNewPasswordDialog} onClose={handleClose}>
        <DialogTitle>
          Set New Password
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="dense"
            type={showNewPassword ? 'text' : 'password'}
            label="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowNewPassword(!showNewPassword)} edge="end">
                    <Icon icon={showNewPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleNewPasswordSubmit} disabled={isLoading}>
            {isLoading ? 'Updating...' : 'Update Password'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
