import React, {useState, memo, useCallback, useEffect, useRef} from 'react'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'

const Editable = memo(({id = '', text = 'No remarks yet.', onEditText = () => {}}) => {
  let [editable, setEditable] = useState(false)
  let [editableText, setEditableText] = useState(text)
  let textEditor = useRef()


  let saveText = useCallback(async () => {
    if(String(editableText)?.trim().length  || 0 >  0) {
      onEditText(editableText)
    }
    else {
      setEditableText(text)
    }
  }, [editableText])

  let onEditing = useCallback((e) => {
  	setEditableText(textEditor.current.value)
    if (e.nativeEvent.which === 13 || e.nativeEvent.which === 27) {
      saveText()
      turnOffEditable()
    }
  }, [])

  let turnOnEditable = useCallback(() => {
    console.log('setting editable')
    setEditable(true)
  }, [])

  let turnOffEditable = useCallback(() => {
    console.log('not setting editable')
    setEditable(false)
  }, [])



  return (
    <>
      {editable ? (
        <ClickAwayListener
          onClickAway={() => {
            saveText()
            turnOffEditable()
          }}
        >
        	<input onChange = {onEditing} value = {editableText} ref = {textEditor}/>
        </ClickAwayListener>
      ) : (
        <div ref={textEditor} onClick={turnOnEditable} contentEditable={editable}>
          {editableText}
        </div>
      )}
    </>
  )
})

export default Editable