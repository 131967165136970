import React, {useRef, useCallback} from 'react'


/*
{
	remarkid: {
	hoursTardy, overtime, nightdiff, rd
	}, 
	//totals store the diffs upon editing of users values
	totals: {
		userid: {
			hoursTardy, overtime, nightdiff, rd
		}
	}
}

*/

const useUpdateScheduleValues = () => {
	let updatedScheduleValues = useRef({totals: {}})

	/**
	 * values could be {
	 * hoursTardy: 23}
	 * 
	 * */
	const updateSchedValues = useCallback((id, values, diff) => {

		console.log(`added values for ${id}`, values)
	    let addedValues = updatedScheduleValues.current
	    let currentValues = addedValues[`${id}`] || {}

	    //update each schedule here
	    updatedScheduleValues.current[`${id}`] = {
	      ...currentValues, 
	      ...values
	    }


	    //update total number of tardy hours, ot, nightdiff, restday here
	    let userid = id.split("-")[0]

	    let currentUserTotals = updatedScheduleValues.current.totals[`${userid}`] || {}

	    Object.keys(values).forEach((key) => {
	    	let currentValueOfThisScheduleValue = currentUserTotals[`${key}`] || 0
	    	currentUserTotals[`${key}`] = currentValueOfThisScheduleValue + diff
	    })

	    //update totals now here
	    updatedScheduleValues.current.totals = {
	    	...updatedScheduleValues.current.totals || {}, 
	    	[`${userid}`]: currentUserTotals
	    }



	    console.log(userid, currentUserTotals)
	    console.log(updatedScheduleValues.current)
	}, [])

	const scheduleValuesOf = useCallback((id = '') => {
		return  {
			...updatedScheduleValues.current[`${id}`]
		}
	}, [])

	const totalValuesOfUserId = useCallback((id = '') => {
		return {
			hoursTardy: 0, 
			overtime: 0, 
			nightdiff: 0,
			rd: 0,
			...updatedScheduleValues.current.totals[`${id}`] || {}
		}
	}, [])

	const getUpdatedScheduleValues = useCallback(() => {
		return updatedScheduleValues.current
	}, [])

	const resetScheduleValues = useCallback(() => {
		updatedScheduleValues.current = {totals: {}}
	} , [])


	return {
		updateSchedValues, 
		scheduleValuesOf, 
		totalValuesOfUserId, 
		getUpdatedScheduleValues,
		resetScheduleValues
	}


}

export default useUpdateScheduleValues