import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  Container,
  Card,
  Divider,
  Button,
  Avatar,
  IconButton,
  Modal,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  InputAdornment
} from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import Page from '../components/Page';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import storage from 'utils/storage';
import user_api from 'utils/api/users';
import jwt_decode from 'jwt-decode';
import { SocketContext } from 'utils/context/socket';
import { useContext } from 'react';
import { MainContext } from 'utils/context/main';
import AccountChangeMPIN from 'components/account/AccountChangeMPIN';
import QrReader from 'react-qr-reader';
import ChangePass from 'components/account/ChangePass';
import OTPInput from 'react-otp-input';

// Styled components
const ProfileInfoItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2, 0),
}));

const InfoLabel = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.text.secondary,
}));

const InfoValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const EditButton = styled(Button)(({ theme }) => ({
  minWidth: '80px',
  borderColor: theme.palette.primary.main,
  color: theme.palette.primary.main,
}));

const DeleteButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  color: theme.palette.error.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.error.dark,
  },
  marginTop: theme.spacing(4),
}));

export default function EmployeeProfile() {
  const theme = useTheme();
  const navigate = useNavigate();
  const socket = useContext(SocketContext);
  const [isLoading, setLoading] = useState(false)
  const { setName, setRoom } = useContext(MainContext);
  const [userData, setUserData] = useState(null);
  const [data, setData] = useState({
    users: null
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openMPINDialog, setOpenMPINDialog] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [confirmation, setConfirmation] = useState('');
  const location = useLocation()
  
  // Added state variables for edit functionality
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openEditCompany, setOpenEditCompany] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  // Added state variables for password change functionality
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);

  // Add these state variables inside the EmployeeProfile component
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [openPhotoDialog, setOpenPhotoDialog] = useState(false);
  const fileInputRef = useRef(null);

  // Add these state variables at the top with other state declarations
  const [fileType, setFileType] = useState('');
  const [photoFilename, setPhotoFileName] = useState('');
  const [uploadUrl, setUploadUrl] = useState('');
  const [binary, setBinary] = useState(null);
  const [image, setImage] = useState(null);

  // Add new state variables with other state declarations
  const [openMobileDialog, setOpenMobileDialog] = useState(false);
  const [newMobile, setNewMobile] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [isSendingOtp, setIsSendingOtp] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  // Add mobile number validation regex
  const PH_MOBILE_REGEX = /^9\d{9}$/;

  // Add new state variables with other email-related states
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [newEmailOtp, setNewEmailOtp] = useState('');
  const [currentStep, setCurrentStep] = useState(1); // 1=enter email, 2=verify new email
  const [isSendingEmailOtp, setIsSendingEmailOtp] = useState(false);
  const [isVerifyingNewEmail, setIsVerifyingNewEmail] = useState(false);

  // Email validation regex
  const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Add these state variables with other state declarations
  const [resendCooldown, setResendCooldown] = useState(0);
  const [canResend, setCanResend] = useState(true);

  // Add these state variables with other state declarations
  const [isSubmittingName, setIsSubmittingName] = useState(false);
  const [isSubmittingDelete, setIsSubmittingDelete] = useState(false);
  const [isSubmittingPhoto, setIsSubmittingPhoto] = useState(false);
  const [isSubmittingMobile, setIsSubmittingMobile] = useState(false);
  const [isSubmittingEmail, setIsSubmittingEmail] = useState(false);
  const [isResendingOtp, setIsResendingOtp] = useState(false);

  // Add these state variables with other state declarations
  const [openCompanyConfirmDialog, setOpenCompanyConfirmDialog] = useState(false);
  const [newCompanyData, setNewCompanyData] = useState(null);
  const [isChangingCompany, setIsChangingCompany] = useState(false);

  // Add this effect hook for cooldown timer
  useEffect(() => {
    let interval;
    if (resendCooldown > 0) {
      interval = setInterval(() => {
        setResendCooldown((prev) => prev - 1);
      }, 1000);
    } else {
      setCanResend(true);
    }
    return () => clearInterval(interval);
  }, [resendCooldown]);

  // Add this helper function
  const startCooldown = () => {
    setCanResend(false);
    setResendCooldown(10);
  };
/*  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    
  }, [open]);*/

  useEffect(() => {

    const load = async () => {
      if(document.body.style.overflow === "hidden"){
        document.body.style.overflow = "auto";   
      }
      
      const local_user = await storage.getUser();

      if (!local_user) return navigate('/login');
      const user = JSON.parse(local_user);
      setUserData(user);

      // Set first and last name from user data
      setFirstName(user.firstName || '');
      setLastName(user.lastName || '');
      
      let name,
        _id,
        uuid = undefined;

      if (user.role === 1 || user.role >= 3) {
        name = user.displayName;
        uuid = _id = user._id;
        socket.emit('connected', { name, uuid, _id }, (error) => {
          if (error) {
            console.log(error);
          }
        });
      } else {
        name = user.displayName;
        _id = user.sid;
        uuid = user._id;
      }

      if (!name || !uuid || !_id) return;
      setName(name);
      setRoom(uuid);
      socket.emit('connected', { name, uuid, _id }, (error) => {
        if (error) {
          console.log(error);
        }
      });
      return setData((p) => ({ ...p, users: user }));
    };

    const check_login = async () => {
      const token = await storage.getToken();
      const current_date = new Date();

      if (!token) {
        await storage.remove();
        return;
      }

      if (jwt_decode(token)['exp'] * 1000 < current_date.getTime()) {
        await storage.remove();
        return navigate('/login');
      }

      const result = await user_api.get_user(jwt_decode(token)['id']);
      if (!result.ok) {
        await storage.remove();
        return navigate('/login');
      }

      if (result.data.isArchived) {
        alert('Your account is disabled');
        return window.location.href = '/login';
      }
      if ((result.data.role === 1 || result.data.role >= 3) && !result.data.isOnBoarded) return navigate('/store/onboard');
      if (result.data.role === 0 && !result.data.isOnBoarded) return navigate(`${result.data.store_id}/onboard`);

      if (result.data.role === 1 || result.data.role >= 3) return navigate('/stores');
    };

    check_login();
    load();
    if (!storage.getScheduleNotify()) setDialogOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  
  // These handlers will be implemented by you as mentioned
  const handleEditInfo = (field) => {
    if (field === 'mpin') {
      handleOpenSetMPIN();
    } else if (field === 'fullName') {
      handleEditDisplayName();
    } else if (field === 'company') {
      handleEditCompanyName();
    } else if (field === 'password') {
      setOpenPasswordDialog(true);
    } else if (field === 'mobileNumber') {
      handleOpenMobileEdit();
    } else if (field === 'email') {
      handleOpenEmailEdit();
    } else {
      console.log(`Edit ${field}`);
      // You will implement this functionality
    }
  };

  const handleEditProfilePhoto = () => {
    setOpenPhotoDialog(true);
  };
  
  const handleOpenSetMPIN = () => {
    setOpenMPINDialog(true);
  }

  const handleCloseMPINDialog = () => {
    setOpenMPINDialog(false);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setConfirmation('');
  };

  const handleDelete = async () => {
    if (confirmation !== "CONFIRM") {
      alert("Confirmation failed");
    } else {
      setIsSubmittingDelete(true);
      try {
        const result = await user_api.archive_user(userData._id, userData._id);
        if (result.status === 200) {
          alert("Delete success.");
          window.location.href = "/login";
        }
      } catch (error) {
        console.error('Error deleting account:', error);
        alert('Error deleting account. Please try again.');
      } finally {
        setIsSubmittingDelete(false);
      }
    }
  };

  // Added handlers for editing display name
  const handleEditDisplayName = () => {
    setFirstName(userData.firstName || '');
    setLastName(userData.lastName || '');
    setOpenEditDialog(true);
  };

  const handleChangeFirstName = async (e) => {
    const value = e.target.value;
    // Check if value contains only letters and spaces
    if (!/^[A-Za-z\s]*$/.test(value)) {
      alert('First name can only contain letters and spaces');
      return;
    }
    // Check length
    if (value.length > 50) {
      alert('First name cannot be longer than 50 characters');
      return;
    }
    setFirstName(value);
  }

  const handleChangeLastName = async (e) => {
    const value = e.target.value;
    // Check if value contains only letters and spaces  
    if (!/^[A-Za-z\s]*$/.test(value)) {
      alert('Last name can only contain letters and spaces');
      return;
    }
    // Check length
    if (value.length > 50) {
      alert('Last name cannot be longer than 50 characters'); 
      return;
    }
    setLastName(value);
  }

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleSubmitUpdates = async () => {
    if (!firstName.trim() || !lastName.trim()) {
      alert('First and last name cannot be empty');
      return;
    }

    setIsSubmittingName(true);
    try {
      const data = {
        firstName: firstName,
        lastName: lastName,
      };
      const result = await user_api.edit_user_profile(userData._id, data);
      if (result.status === 200) {
        alert('Update profile success.');
        setUserData((prev) => ({...prev, displayName: `${firstName} ${lastName}`, firstName: firstName, lastName: lastName}));
        await storage.storeUser({...userData, displayName: `${firstName} ${lastName}`, firstName: firstName, lastName: lastName})
        
        setOpenEditDialog(false);
      } else {
        alert('Something went wrong please try again later!');
      }
    } catch (error) {
      console.error('Error updating name:', error);
      alert('Error updating name. Please try again.');
    } finally {
      setIsSubmittingName(false);
    }
  };

  // Added handlers for editing company name
  const handleEditCompanyName = async () => {
    setOpenEditCompany(true);
  };

  const handleCloseEditCompany = () => {
    setOpenEditCompany(false);
  };

  const handleScanStoreQR = async (res) => {
    if (res) {
      const idFromUrl = res.split('/').filter(Boolean)[2];
      const result = await user_api.get_store(idFromUrl);
      if(result.status === 200) {
        if(result.data.company === userData.company) {
          alert("You are already in this company");
          return;
        }
        
        // Store the new company data and show confirmation dialog
        setNewCompanyData(result.data);
        setOpenCompanyConfirmDialog(true);
      } else {
        if(result.data.msg) {
          alert(result.data.msg);
        } else {
          alert('No link found in the response.');
        }
      }
    } else {
      alert('No link found in the response.');
    }
  };

  const handleConfirmCompanyChange = async () => {
    if (!newCompanyData) return;
    
    try {
      setIsChangingCompany(true);
      const data = {
        company: newCompanyData.company,
      };
      
      const updateResult = await user_api.edit_user_company(userData._id, data);
      if (updateResult.status === 200) {
        setUserData((prev) => ({...prev, company: newCompanyData.company}));
        await storage.storeUser({...userData, company: newCompanyData.company})
        
        alert('Company update success!');
        setOpenCompanyConfirmDialog(false);
        setOpenEditCompany(false);
      } else {
        if(updateResult.data.msg) {
          alert(updateResult.data.msg);
        } else {
          alert('Something went wrong please try again later');
        }
      }
    } catch (error) {
      console.error('Error updating company:', error);
      alert('Error updating company. Please try again.');
    } finally {
      setIsChangingCompany(false);
    }
  };

  const handleCancelCompanyChange = () => {
    setOpenCompanyConfirmDialog(false);
    setNewCompanyData(null);
  };

  const handleErrorStoreQR = (err) => {
    alert(err);
  };

  // Format the name for display
  const fullName = userData ? `${userData.firstName} ${userData.lastName}` : '';
  
  // Mask sensitive data
  const maskedPassword = '••••••••';
  const maskedMpin = '••••';

  // Modal style
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  // Add these handlers inside the EmployeeProfile component
  const handleClosePhotoDialog = () => {
    setOpenPhotoDialog(false);
    setSelectedImage(null);
    setPreviewUrl(null);
  };

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // Only allow image files
      if (!file.type.startsWith('image/')) {
        alert('Please select an image file');
        return;
      }

      // Create preview URL
      const fileUrl = URL.createObjectURL(file);
      setPreviewUrl(fileUrl);
      setSelectedImage(file);

      // Get signed URL for upload
      const to_base64 = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });

      const blob = await to_base64(file);
      const imageUrl = URL.createObjectURL(file);
      const data = {
        file_type: file.type
      };
      
      setFileType(file.type);
      
      try {
        const get_url = await user_api.request_upload_url(data);
        if (!get_url) {
          alert("Something went wrong please try again later");
          return;
        }
        
        const { photoFilename, uploadURL } = get_url.data;
        setPhotoFileName(photoFilename);
        setUploadUrl(uploadURL);
        setBinary(atob(blob.split(',')[1]));
        setImage(imageUrl);
      } catch (error) {
        console.error('Error getting upload URL:', error);
        alert('Error preparing file upload. Please try again.');
      }
    }
  };

  const handleUploadPhoto = async () => {
    if (!selectedImage || !uploadUrl) return;

    setIsSubmittingPhoto(true);
    try {
      const array = [];
      let data = {};
      if(!binary) {
        alert("Please upload image")
      }
      else {
          for (let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
          }

          const blobData = new Blob([new Uint8Array(array)], {
            type: fileType
          });

          const result = await fetch(uploadUrl, {
            method: 'PUT',
            body: blobData
          }).then(async (response) => {
            const img = response.url.split('?')[0]
            if (response.status) {
               data = {
                id: userData._id,
                image: img
              };
            }
          }
        )
      }

      const result = await user_api.edit_profile_pic(data);
      
      if (result.status === 200) {
        // Update local user data with new image URL
        const updatedUser = { ...userData, image: data.image };
        setUserData(updatedUser);
        await storage.storeUser(updatedUser);
        
        alert('Profile photo updated successfully');
        handleClosePhotoDialog();
      } else {
        alert('Failed to update profile photo');
      }
    } catch (error) {
      console.error('Error uploading photo:', error);
      alert('Error uploading photo. Please try again.');
    } finally {
      setIsSubmittingPhoto(false);
    }
  };

  // Add these handlers before the return statement
  const handleOpenMobileEdit = () => {
    setOpenMobileDialog(true);
    setNewMobile('');
    setOtp('');
    setOtpSent(false);
  };

  const handleCloseMobileDialog = () => {
    setOpenMobileDialog(false);
    setNewMobile('');
    setOtp('');
    setOtpSent(false);
    setResendCooldown(0);
    setCanResend(true);
  };

  const handleMobileEditChange = (value) => {
    setNewMobile(value);
  }

  const handleSendMobileOtp = async () => {
    if (!PH_MOBILE_REGEX.test(newMobile)) {
      alert('Please enter a valid Philippine mobile number (9XXXXXXXXX)');
      return;
    }

    if (newMobile === userData?.phone) {
      alert('New mobile number must be different from current number');
      return;
    }

    try {
      setIsSubmittingMobile(true);
      startCooldown();
      const result = await user_api.send_otp_for_mobile_change({
        userId: userData._id,
        oldMobile: userData.phone,
        newMobile: '0' + newMobile
      });

      if (result.status === 200) {
        setOtpSent(true);
        alert('OTP sent to your new mobile number');
      } else {
        if(result.data.msg) {
          alert(result.data.msg);
        } else {
          alert('Failed to send OTP. Please try again.');
        }
      }
    } catch (error) {
      console.error('OTP send error:', error);
      if(error.response.data.msg) {
        alert(error.response.data.msg);
      } else {
        alert('Error sending OTP. Please try again.');
      }
    } finally {
      setIsSubmittingMobile(false);
    }
  };

  const handleVerifyMobileOtp = async () => {
    if (otp.length !== 6) {
      alert('Please enter a 6-digit OTP');
      return;
    }

    try {
      setIsVerifying(true);
      const result = await user_api.verify_mobile_change_otp({
        userId: userData._id,
        otp,
        newMobile: '0' + newMobile
      });

      if (result.status === 200) {
        // Update local state and storage
        const updatedUser = { ...userData, phone: '+63' + newMobile };
        setUserData(updatedUser);
        await storage.storeUser(updatedUser);
        
        alert('Mobile number updated successfully!');
        handleCloseMobileDialog();
      } else {
        if(result.data.msg) {
          alert(result.data.msg);
        } else {
          alert('Invalid OTP or verification failed. Please try again.');
        }
      }
    } catch (error) {
      console.error('Verification error:', error);
      if(error.response.data.msg) {
        alert(error.response.data.msg);
      } else {
        alert('Error verifying OTP. Please try again.');
      }
    } finally {
      setIsVerifying(false);
    }
  };

  // Add email handlers
  const handleOpenEmailEdit = () => {
    setOpenEmailDialog(true);
    setNewEmail('');
    setNewEmailOtp('');
    setCurrentStep(1);
  };

  const handleCloseEmailDialog = () => {
    setOpenEmailDialog(false);
    setNewEmail('');
    setNewEmailOtp('');
    setCurrentStep(1);
    setResendCooldown(0);
    setCanResend(true);
  };

  const handleSendEmailOtps = async () => {
    if (!EMAIL_REGEX.test(newEmail)) {
      alert('Please enter a valid email address');
      return;
    }

    if (newEmail === userData?.email) {
      alert('New email must be different from current email');
      return;
    }

    try {
      setIsSubmittingEmail(true);
      const result = await user_api.send_email_change_otps({
        userId: userData._id,
        newEmail
      });

      if (result.ok) {
        setCurrentStep(2);
        alert('Verification code sent to your new email address');
      } else {
        if(result.data.msg) {
          alert(result.data.msg);
        } else {
          alert('Failed to send verification code');
        }
      }
    } catch (error) {
      console.error('Email OTP send error:', error);
      if(error.response.data.msg) {
        alert(error.response.data.msg);
      } else {
        alert('Error sending verification code');
      }
    } finally {
      setIsSubmittingEmail(false);
    }
  };

  const handleResendEmailOtps = async () => {
    try {
      setIsResendingOtp(true);
      startCooldown();
      const result = await user_api.send_email_change_otps({
        userId: userData._id,
        newEmail
      });

      if (result.ok) {
        alert('Verification code resent to your new email address');
      } else {
        alert('Failed to resend verification code');
      }
    } catch (error) {
      console.error('Email OTP resend error:', error);
      alert('Error resending verification code');
    } finally {
      setIsResendingOtp(false);
    }
  };

  const handleVerifyNewEmailOtp = async () => {
    if (newEmailOtp.length !== 6) {
      alert('Please enter a 6-digit code');
      return;
    }

    try {
      setIsVerifyingNewEmail(true);
      const result = await user_api.verify_email_change_otp({
        userId: userData._id,
        otp: newEmailOtp,
        newEmail: newEmail
      });

      if (result.status === 200) {
        // Update local state and storage
        const updatedUser = { ...userData, email: newEmail };
        setUserData(updatedUser);
        await storage.storeUser(updatedUser);
        
        alert('Email address updated successfully!');
        handleCloseEmailDialog();
      } else {
        alert(result.data.msg);
      }
    } catch (error) {
      console.error('New email verification error:', error);
      if(error.response.data.msg) {
        alert(error.response.data.msg);
      } else {
        alert('Error verifying new email code');
      }
    } finally {
      setIsVerifyingNewEmail(false);
    }
  };

  return (
    <Page title="Employee Profile">
      <Container maxWidth="md">
        <Card sx={{ p: 5, mb: 5 }}>
          <Box sx={{ textAlign: 'center', mb: 5 }}>
            <Avatar
              src={userData?.image || '/static/mock-images/avatars/avatar_default.jpg'}
              alt={fullName}
              sx={{
                width: 120,
                height: 120,
                mx: 'auto',
                border: `4px solid ${theme.palette.background.paper}`,
                boxShadow: theme.shadows[2],
              }}
            />
            <Button
              variant="outlined"
              size="small"
              onClick={handleEditProfilePhoto}
              sx={{ mt: 2 }}
              startIcon={<EditIcon />}
            >
              Edit Profile Photo
            </Button>
          </Box>

          <Typography variant="h4" sx={{ mb: 4, textAlign: 'center' }}>
            My Profile
          </Typography>

          {/* Full Name */}
          <ProfileInfoItem>
            <Box>
              <InfoLabel variant="subtitle1">Full Name</InfoLabel>
              <InfoValue variant="body1" sx={{
                wordWrap: 'break-word',
                wordBreak: 'break-all',
                overflow: 'hidden'
              }}>{userData?.displayName || fullName}</InfoValue>
            </Box>
            <EditButton 
              variant="outlined" 
              onClick={() => handleEditInfo('fullName')}
              aria-label="edit full name"
            >
              Edit
            </EditButton>
          </ProfileInfoItem>
          <Divider />

          {/* Company */}
          <ProfileInfoItem>
            <Box>
              <InfoLabel variant="subtitle1">Company</InfoLabel>
              <InfoValue variant="body1" sx={{
                wordWrap: 'break-word',
                wordBreak: 'break-all',
                overflow: 'hidden'
              }}>{userData?.company || 'Not specified'}</InfoValue>
            </Box>
            <EditButton 
              variant="outlined" 
              onClick={() => handleEditInfo('company')}
              aria-label="edit company"
            >
              Edit
            </EditButton>
          </ProfileInfoItem>
          <Divider />

          {/* Email */}
          <ProfileInfoItem>
            <Box>
              <InfoLabel variant="subtitle1">Email Address</InfoLabel>
              <InfoValue variant="body1" sx={{
                wordWrap: 'break-word',
                wordBreak: 'break-all',
                overflow: 'hidden'
              }}>{userData?.email || 'Not specified'}</InfoValue>
            </Box>
            <EditButton 
              variant="outlined" 
              onClick={() => handleEditInfo('email')}
              aria-label="edit email"
            >
              Edit
            </EditButton>
          </ProfileInfoItem>
          <Divider />

          {/* Password */}
          <ProfileInfoItem>
            <Box>
              <InfoLabel variant="subtitle1">Password</InfoLabel>
              <InfoValue variant="body1" sx={{
                wordWrap: 'break-word',
                wordBreak: 'break-all',
                overflow: 'hidden'
              }}>{maskedPassword}</InfoValue>
            </Box>
            <EditButton 
              variant="outlined" 
              onClick={() => handleEditInfo('password')}
              aria-label="edit password"
            >
              Edit
            </EditButton>
          </ProfileInfoItem>
          <Divider />

          {/* Mobile Number */}
          <ProfileInfoItem>
            <Box>
              <InfoLabel variant="subtitle1">Mobile Number</InfoLabel>
              <InfoValue variant="body1" sx={{
                wordWrap: 'break-word',
                wordBreak: 'break-all',
                overflow: 'hidden'
              }}>{userData?.phone || 'Not specified'}</InfoValue>
            </Box>
            <EditButton 
              variant="outlined" 
              onClick={() => handleEditInfo('mobileNumber')}
              aria-label="edit mobile number"
            >
              Edit
            </EditButton>
          </ProfileInfoItem>
          <Divider />

          {/* MPIN */}
          <ProfileInfoItem>
            <Box>
              <InfoLabel variant="subtitle1">MPIN</InfoLabel>
              <InfoValue variant="body1" sx={{
                wordWrap: 'break-word',
                wordBreak: 'break-all',
                overflow: 'hidden'
              }}>{maskedMpin}</InfoValue>
            </Box>
            <EditButton 
              variant="outlined" 
              onClick={() => handleEditInfo('mpin')}
              aria-label="edit mpin"
            >
              Edit
            </EditButton>
          </ProfileInfoItem>

          {/* Delete Account Button */}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <DeleteButton 
              variant="contained" 
              onClick={handleOpenDelete}
            >
              Delete Account
            </DeleteButton>
          </Box>
        </Card>
      </Container>

      {/* MPIN Dialog */}
      {userData && (
        <AccountChangeMPIN 
          open={openMPINDialog}
          onClose={handleCloseMPINDialog}
          user={userData}
        />
      )}

      {/* Delete Account Modal */}
      <Modal open={openDelete} onClose={handleCloseDelete}>
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            Are you sure you want to delete this account?
          </Typography>
          <Box mt={2}>
            <Typography color="error" variant="body2">
              ⚠️ Warning: Deleting your account is irreversible. All your account information, settings, data, and privileges will be permanently erased.
            </Typography>
            <Typography mt={1}>To confirm, please type: CONFIRM</Typography>
            <TextField
              label="Confirmation"
              variant="outlined"
              fullWidth
              value={confirmation}
              onChange={(e) => setConfirmation(e.target.value)}
              sx={{ mt: 2 }}
            />
            <Box mt={2} display="flex" justifyContent="space-between">
              <Button variant="outlined" color="primary" onClick={handleCloseDelete}>
                No
              </Button>
              <Button 
                variant="contained" 
                color="error" 
                onClick={handleDelete}
                disabled={isSubmittingDelete}
              >
                {isSubmittingDelete ? 'Deleting...' : 'Yes'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Edit Name Dialog */}
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <IconButton
          aria-label="close"
          onClick={handleCloseEditDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>Edit Name</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="firstName"
            label="First Name"
            type="text"
            fullWidth
            variant="standard"
            value={firstName}
            onChange={handleChangeFirstName}
          />
          <TextField
            margin="dense"
            id="lastName"
            label="Last Name"
            type="text"
            fullWidth
            variant="standard"
            value={lastName}
            onChange={handleChangeLastName}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog}>Cancel</Button>
          <Button 
            onClick={handleSubmitUpdates}
            disabled={isSubmittingName}
            variant="contained"
          >
            {isSubmittingName ? 'Submitting...' : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Company Dialog */}
      <Dialog 
        open={openEditCompany} 
        onClose={handleCloseEditCompany}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            overflow: 'visible',
            maxHeight: '80vh'
          }
        }}
      >
        <DialogTitle>
          Scan store QR code.
          <IconButton
            aria-label="close"
            onClick={handleCloseEditCompany}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
          <QrReader
            delay={3000}
            onError={handleErrorStoreQR}
            onScan={handleScanStoreQR}
            style={{width: '300px', height: '300px'}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditCompany} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Replace all password-related dialogs with this single component */}
      <ChangePass 
        open={openPasswordDialog}
        onClose={() => setOpenPasswordDialog(false)}
        email={userData?.email}
      />

      {/* Photo Dialog */}
      <Dialog open={openPhotoDialog} onClose={handleClosePhotoDialog}>
        <DialogTitle>Update Profile Photo</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClosePhotoDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box sx={{ textAlign: 'center' }}>
            {previewUrl ? (
              <Avatar
                src={previewUrl}
                sx={{
                  width: 200,
                  height: 200,
                  mx: 'auto',
                  mb: 2,
                }}
              />
            ) : (
              <Avatar
                src={userData?.image || '/static/mock-images/avatars/avatar_default.jpg'}
                sx={{
                  width: 200,
                  height: 200,
                  mx: 'auto',
                  mb: 2,
                }}
              />
            )}
            <input
              type="file"
              accept="image/*"
              hidden
              ref={fileInputRef}
              onChange={handleFileSelect}
            />
            <Button
              variant="outlined"
              onClick={() => fileInputRef.current.click()}
              sx={{ mb: 2 }}
            >
              Choose Photo
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePhotoDialog}>Cancel</Button>
          <Button 
            onClick={handleUploadPhoto}
            disabled={!selectedImage || isSubmittingPhoto}
            variant="contained"
          >
            {isSubmittingPhoto ? 'Uploading...' : 'Upload'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Mobile Number Edit Dialog */}
      <Dialog open={openMobileDialog} onClose={handleCloseMobileDialog}>
        <DialogTitle>
          Change Mobile Number
          <IconButton
            aria-label="close"
            onClick={handleCloseMobileDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Current Number: {userData?.phone}
            </Typography>

            {!otpSent ? (
              <>
                <TextField
                  fullWidth
                  label="New Mobile Number"
                  variant="outlined"
                  value={newMobile}
                  onChange={(e) => handleMobileEditChange(e.target.value)}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+63</InputAdornment>,
                  }}
                  disabled={isSendingOtp}
                  error={!!newMobile && !PH_MOBILE_REGEX.test(newMobile)}
                  helperText={!!newMobile && !PH_MOBILE_REGEX.test(newMobile) && 'Must be 9 followed by 9 digits'}
                />
                <Typography variant="caption" color="textSecondary" sx={{ mt: 1 }}>
                  You'll receive OTPs on both old and new numbers
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="body1" gutterBottom>
                  Enter OTP sent to 0{newMobile}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
                <OTPInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  inputType="number"
                  shouldAutoFocus
                  containerStyle={{ display: "flex", gap: "8px" }}
                  inputStyle={{
                    width: "3rem",
                    height: "3rem",
                    fontSize: "1.5rem",
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                    textAlign: "center",
                    outline: "none",
                    background: "#fff",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    transition: "border-color 0.2s",
                  }}
                  renderInput={(props) => <input {...props} />}
                />
                </Box>
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          {!otpSent ? (
            <Button 
              onClick={() => {
                handleSendMobileOtp();
              }}
              disabled={!PH_MOBILE_REGEX.test(newMobile) || isSendingOtp || !canResend}
              variant="contained"
            >
              {isSendingOtp ? 'Sending...' : canResend ? 'Send OTP' : `Resend in ${resendCooldown}s`}
            </Button>
          ) : (
            <Button 
              onClick={handleVerifyMobileOtp} 
              disabled={otp.length !== 6 || isVerifying}
              variant="contained"
            >
              {isVerifying ? 'Verifying...' : 'Verify OTP'}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* Email Edit Dialog */}
      <Dialog open={openEmailDialog} onClose={handleCloseEmailDialog} maxWidth="sm" fullWidth>
        <DialogTitle>
          Change Email Address
          <IconButton
            aria-label="close"
            onClick={handleCloseEmailDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            {currentStep === 1 && (
              <>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Current Email: {userData?.email}
                </Typography>
                <TextField
                  fullWidth
                  label="New Email Address"
                  variant="outlined"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                  disabled={isSubmittingEmail}
                  error={!!newEmail && !EMAIL_REGEX.test(newEmail)}
                  helperText={!!newEmail && !EMAIL_REGEX.test(newEmail) && 'Please enter a valid email address'}
                  sx={{ mb: 2 }}
                />
                <Typography variant="caption" color="textSecondary">
                  You'll need to verify your new email address
                </Typography>
              </>
            )}

            {currentStep === 2 && (
              <>
                <Typography variant="body1" gutterBottom>
                  Verification code sent to {newEmail}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
                <OTPInput
                  value={newEmailOtp}
                  onChange={setNewEmailOtp}
                  numInputs={6}
                  inputType="number"
                  shouldAutoFocus
                  containerStyle={{ display: "flex", gap: "8px" }}
                  inputStyle={{
                    width: "3rem",
                    height: "3rem",
                    fontSize: "1.5rem",
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                    textAlign: "center",
                    outline: "none",
                    background: "#fff",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    transition: "border-color 0.2s",
                  }}
                  renderInput={(props) => <input {...props} />}
                />
                </Box>
                <Box sx={{ textAlign: 'center', mt: 1 }}>
                  <Button 
                    onClick={() => {
                      handleResendEmailOtps();
                    }}
                    disabled={!canResend || isResendingOtp}
                    size="small"
                  >
                    {isResendingOtp ? 'Resending...' : canResend ? 'Resend OTP' : `Resend in ${resendCooldown}s`}
                  </Button>
                </Box>
                <Typography variant="caption" color="textSecondary">
                  Check your new email for the verification code
                </Typography>
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          {currentStep === 1 && (
            <Button
              onClick={() => handleSendEmailOtps()}
              disabled={!EMAIL_REGEX.test(newEmail) || isSubmittingEmail}
              variant="contained"
            >
              {isSubmittingEmail ? 'Sending...' : 'Send Verification Code'}
            </Button>
          )}
          {currentStep === 2 && (
            <Button
              onClick={() => handleVerifyNewEmailOtp()}
              disabled={newEmailOtp.length !== 6 || isVerifyingNewEmail}
              variant="contained"
            >
              {isVerifyingNewEmail ? 'Verifying...' : 'Verify & Update'}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* Company Change Confirmation Dialog */}
      <Dialog open={openCompanyConfirmDialog} onClose={handleCancelCompanyChange}>
        <DialogTitle>Confirm Company Change</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to change your company?
          </DialogContentText>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2">Current Company:</Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>{userData?.company || 'Not specified/No company'}</Typography>
            
            <Typography variant="subtitle2">New Company:</Typography>
            <Typography variant="body1">{newCompanyData?.company || ''}</Typography>
          </Box>
          <DialogContentText sx={{ mt: 2, color: 'warning.main' }}>
            ⚠️ This action may affect your access to current company resources.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelCompanyChange}>Cancel</Button>
          <Button 
            onClick={handleConfirmCompanyChange} 
            variant="contained"
            disabled={isChangingCompany}
          >
            {isChangingCompany ? 'Changing...' : 'Confirm Change'}
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}
