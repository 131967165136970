export default [
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'schedule', label: 'Schedule', alignRight: false },
  { id: 'timein', label: 'Time-in', alignRight: false },
  { id: 'timeout', label: 'Time-out', alignRight: false },
  { id: 'workinghours', label: 'Working Hours', alignRight: false },
  { id: 'lates', label: 'Lates (Minutes)', alignRight: false },
  { id: 'ot', label: 'Overtime (Hours)', alignRight: false },
  { id: 'nightdiff', label: 'Night Diff (Hours)', alignRight: false },
  { id: 'rd', label: 'RestDay (Hours)', alignRight: false },
  { id: 'legalholiday', label: 'Legal Holiday (Hours)', alignRight: false },
  { id: 'specialholiday', label: 'Special Holiday (Hours)', alignRight: false },
  
  { id: 'remarks', label: 'Remarks', alignRight: false},
]