import React from 'react';
import { Box, Typography } from '@mui/material';
import { Icon } from '@iconify/react';

const FourDigitDisplay = ({ value = '', type, sx }) => {
  // Convert the value to a string and split it into digits
  const digits = value.toString().split('') || [];

  // Pad the array with empty strings if there are fewer than 4 digits
  while (digits.length < 4) {
    digits.push('');
  }

  // Only display the first 4 digits in case a longer string is passed
  const displayDigits = digits.slice(0, 4);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
        ...sx
      }}
    >
      {displayDigits.map((digit, index) => (
        <Box
          key={index}
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="h4">
            {type === 'set' && (digit ? digit : <Icon icon="line-md:circle" />)}
            {type === 'enter' && (digit ? <Icon icon="line-md:circle-twotone" /> : <Icon icon="line-md:circle" />)}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default FourDigitDisplay;
