import React, {useState, useMemo, useCallback} from 'react'
import { UserListHead, UserListToolbar } from 'components/_dashboard/user'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import Stack from '@material-ui/core/Stack'

import moment from 'moment-timezone'

import TABLEHEAD from './config/tablehead'
import Editable from 'components/editable/number'
import EditableText from 'components/editable/text'

import UsersAPI from 'utils/api/users'

import useUpdateScheduleValues from 'hooks/useUpdateScheduleValues'

moment().tz('Asia/Manila').format()

const formatTableNumber = (value) => {
	if (value === null || value === undefined || value === '') return '0.00';
	const num = typeof value === 'string' ? parseFloat(value) : Number(value);
	return isNaN(num) ? '0.00' : (num < 0 ? '0.00' : num.toFixed(2));
};

const UserBreaklistTable = ({onEditValues = () => {}, breaklist = [], breaklistId = '', onSave = () => {}}) => {
	const {
		updateSchedValues, 
		scheduleValuesOf,
		getUpdatedScheduleValues,
		resetScheduleValues
	} = useUpdateScheduleValues()

	let [pageNumber, setPageNumber] = useState(0)
	let [rowsPerPage, setRowsPerPage] = useState(5)
	let [order, setOrder] = useState('asc') //asc or desc
	let [orderBy, setOrderBy] = useState('')

	const changePageNumber  = useCallback((_e, newPage) => {
		setPageNumber(newPage)
	} ,[])

	const changeNumberOfRowsPerPage = useCallback((e) => {
		setRowsPerPage(parseInt(e.target.value, 10))
		setPageNumber(0)
	}, [])

	const changeOrderBy = useCallback((_, property) => {
		const isAsc = orderBy === property && order === 'asc'
    	setOrder(isAsc ? 'desc' : 'asc')
    	setOrderBy(property)
	}, [])


	//save schedule values to backend, updates payroll entry
	/**
	 * {
	 	uid, date, breaklistId{
			otHours, nightdiff, hoursTardy, restday
	 	}
	 * }
	 * 
	 * */
	const saveScheduleValues = useCallback( async (updates) => {
		
		console.log('saving schedule values',updates)
		try{
			let request = await UsersAPI.patch_schedule_employee(updates)
			if(request.status !== 200 || !request.ok){
				alert('Error saving schedule values')
			}
			console.log(request)
		}catch(err){
			console.log(err)
		}
	}, [])

	/**
	 *  {
	 	 remarkID, remarks
	 * 	}
	 * 
	 * */
	const saveRemarks = useCallback(async (id, remark) => {
		try{
			let request = await UsersAPI.update_schedule_remarks({
				id, remark, 
				breaklistId
			})

			console.log(request)
		}catch(err){
			console.log(err)
		}

	}, [])


	const computeTotals = useCallback(() => {
		let totals = breaklist.reduce((acc, {_id, timeIn, timeOut, hoursTardy, overtime, nightdiff, hourswork, rd, date}) => {
			let remarkId = `${_id}-${date}-${timeIn}-${timeOut}`;

			// Properly handle number conversions with null checks
			let computedHoursTardy = scheduleValuesOf(remarkId)?.['hoursTardy'] !== undefined ? 
				Number(scheduleValuesOf(remarkId)?.['hoursTardy']) : 
				Number(hoursTardy) || 0;

			let computedOvertime = scheduleValuesOf(remarkId)?.['overtime'] !== undefined ? 
				Number(scheduleValuesOf(remarkId)?.['overtime']) : 
				Number(overtime) || 0;

			let computedNightdiff = scheduleValuesOf(remarkId)?.['nightdiff'] !== undefined ? 
				Number(scheduleValuesOf(remarkId)?.['nightdiff']) : 
				Number(nightdiff) || 0;

			let computedRestday = scheduleValuesOf(remarkId)?.['rd'] !== undefined ? 
				Number(scheduleValuesOf(remarkId)?.['rd']) : 
				Number(rd) || 0;

			// Ensure no negative values
			computedHoursTardy = computedHoursTardy < 0 ? 0 : computedHoursTardy;
			computedOvertime = computedOvertime < 0 ? 0 : computedOvertime;
			computedNightdiff = computedNightdiff < 0 ? 0 : computedNightdiff;
			computedRestday = computedRestday < 0 ? 0 : computedRestday;

			return {
				hoursTardy: acc.hoursTardy + computedHoursTardy,
				otHours: acc.otHours + computedOvertime,
				nightdiff: acc.nightdiff + computedNightdiff,
				rd: acc.rd + computedRestday
			};
		}, {hoursTardy: 0, otHours: 0, nightdiff: 0, rd: 0});

		// Format all totals to 2 decimal places
		return {
			hoursTardy: Number(totals.hoursTardy.toFixed(2)),
			otHours: Number(totals.otHours.toFixed(2)),
			nightdiff: Number(totals.nightdiff.toFixed(2)),
			rd: Number(totals.rd.toFixed(2))
		};
	}, [breaklist, scheduleValuesOf]);

	return (<>
		<Table>
			<UserListHead
				order = {order}
				headLabel = {TABLEHEAD}
				rowCount = {breaklist?.length || 0}
				onRequestSort = {changeOrderBy}
			/>
			<TableBody>
			{breaklist.slice(pageNumber* rowsPerPage, pageNumber * rowsPerPage + rowsPerPage).map((row, index) => {

				const {
                	_id,
                    name,
                   	date,
                   	from,
                    to,
                    timeIn,
                    timeOut,
                    hoursTardy,
                    overtime,
                    nightdiff,
                    hourswork,
                    rd,
                    legalholiday,
                    specialholiday, 
                   remarks
                } = row

               	let id = `${_id}/${date}/${timeIn}/${timeOut}`

               	let remarkId = `${_id}-${date}-${timeIn}-${timeOut}`

                let workHours = !timeIn && !timeOut && hourswork <= 0 ? <div style = {{color: 'red'}}>"Absent" </div> : formatTableNumber(hourswork)

                const formattedDate = moment(date).format('YYYY-MM-DD');

                let tardy = scheduleValuesOf(remarkId)?.['hoursTardy'] || hoursTardy || 0

                let ot = scheduleValuesOf(remarkId)?.['overtime'] || overtime  || 0

                let nd =  scheduleValuesOf(remarkId)?.['nightdiff'] ||  nightdiff || 0

                let restday =  scheduleValuesOf(remarkId)?.['rd'] || rd || 0


				return (
                    <TableRow
                        hover
                        key={`${_id}-${index}`}
                        tabIndex={-1}
                        role="checkbox"
                    >
                        <TableCell align="left">
                    		{formattedDate}
                        </TableCell>
                        <TableCell align="left">from {from} to {to}</TableCell>
                        <TableCell align="left">{timeIn}</TableCell>
                        <TableCell align="left">{timeOut}</TableCell>
                        <TableCell align="left">{workHours}</TableCell>
                        <TableCell align="left">
		                    <Editable key = {`${id}-hourstardy`} text = {tardy} onEditText = {(newText, diff) => {

			                    updateSchedValues(remarkId, {
	                                hoursTardy: Number(newText)
	                            }, diff)

	                           	let newTotals = computeTotals()
	                         
		                     	saveScheduleValues({
		                      		uid: _id, 
		                       		date: date,
		                       		diff: diff, 
		                       		breaklistId: breaklistId, 
		                       		updates: {
		                     			hoursTardy: Number(newText)
		                       		}, 
		                       		totals: newTotals
		                       	})

								   onSave()

		               	    }}/>
	                        </TableCell>
                        <TableCell align="left">
	                        <Editable key = {`${id}-overtime`} text = {ot} onEditText = {(newText, diff) => {

	                        	updateSchedValues(remarkId, {
                                	overtime: Number(newText)
                                }, diff)

                                let newTotals = computeTotals()

	                        	saveScheduleValues({
	                        		uid: _id, 
	                        		date: date,
	                        		breaklistId: breaklistId, 
	                        		diff: diff,
	                        		updates: {
	                        			otHours: Number(newText)
	                        		}, 
	                        		totals: newTotals
	                        	})

	                        	onSave()

	                        }}/>
                        </TableCell>
                        <TableCell align="left">
                        	<Editable key = {`${id}-nightdiff`} text = {nd} onEditText = {(newText, diff) => {

                        		updateSchedValues(remarkId, {
                                    nightdiff: Number(newText)
                                }, diff)

                                let newTotals = computeTotals()

	                        	saveScheduleValues({
	                        		uid: _id, 
	                        		date: date, 
	                        		breaklistId: breaklistId,
	                        		diff: diff,
	                        		updates: {
	                        			nightdiff: Number(newText)
	                        		}, 
	                        		totals: newTotals
	                        	})

								onSave()

	                        }}/>
                        </TableCell>
						<TableCell align="left">
							<Editable key = {`${id}-rd`} text = {restday} onEditText = {(newText, diff) => {

								updateSchedValues(remarkId, {
                                	rd: Number(newText)
                                }, diff)

                                let newTotals = computeTotals()

	                        	saveScheduleValues({
	                        		uid: _id, 
	                        		date: date, 
	                        		breaklistId: breaklistId,
	                        		diff: diff,
	                        		updates: {
	                        			rd: Number(newText)
	                        		}, 
	                        		totals: newTotals
	                        	})

								onSave()

	                        }}/>
						</TableCell>
						<TableCell align="left">{legalholiday}</TableCell>
						<TableCell align="left">{specialholiday}</TableCell>
                        <TableCell align="left">
                        	<EditableText key = {`${id}-remarks`} text = {remarks || "no remarks"} onEditText = {(newText) => {
                        		saveRemarks(remarkId, newText)
                        	}}/>
                        </TableCell>
                    </TableRow>
                )
			})}

 					<TableRow
                        hover
                        tabIndex={-1}
                        role="checkbox"
						style={{backgroundColor: '#e0e0e0'}}
                    >
                        <TableCell align="left" sx={{ fontWeight: 'bold' }}>Total</TableCell>
                        <TableCell align="left">-</TableCell>
                        <TableCell align="left">-</TableCell>
                        <TableCell align="left">-</TableCell>
                        <TableCell align="left" style = {{fontWeight: 'bold'}}>
                            {formatTableNumber(
                                breaklist.reduce((acc, {hourswork}) => {
                                    const hours = Number(hourswork) || 0;
                                    return acc + (hours < 0 ? 0 : hours);
                                }, 0)
                            )}
                        </TableCell>
                        <TableCell align="left" style = {{fontWeight: 'bold'}} >
		                	{computeTotals().hoursTardy}
	                    </TableCell>
                        <TableCell align="left" style = {{fontWeight: 'bold'}}>
	                      	{computeTotals().otHours} 
                        </TableCell>
                        <TableCell align="left" style = {{fontWeight: 'bold'}}>
                        	{computeTotals().nightdiff}	
                        </TableCell>
						<TableCell align="left" style = {{fontWeight: 'bold'}}>
							{computeTotals().rd}
						</TableCell>
						<TableCell align="left" style = {{fontWeight: 'bold'}}>0</TableCell>
						<TableCell align="left" style = {{fontWeight: 'bold'}}>0</TableCell>

                        <TableCell align="left">
                        	-
                        </TableCell>
                    </TableRow>
			</TableBody>

			<TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={breaklist.length}
                rowsPerPage={rowsPerPage}
                page={pageNumber}
                onPageChange={changePageNumber}
                onRowsPerPageChange={changeNumberOfRowsPerPage}
                labelRowsPerPage={"Rows per page"}
                labelDisplayedRows={({ from, to, count }) => {
				    return `${from}-${to} of ${count}`;
				}}

             />
		</Table>

	</>)
}





export default UserBreaklistTable 

