import { Link as RouterLink, useNavigate } from 'react-router-dom'
import React, { useRef, useState, useEffect, useContext } from 'react'
import { Icon } from '@iconify/react'
import homeFill from '@iconify/icons-eva/home-fill'
import personFill from '@iconify/icons-eva/person-fill'
import settings2Fill from '@iconify/icons-eva/settings-2-fill'
import QrReader from 'react-qr-reader'
import { alpha } from '@material-ui/core/styles'
import QRCode from "qrcode.react"
import jwt_decode from 'jwt-decode'
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Avatar,
  IconButton,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
  Container,
  FormControlLabel,
  Checkbox,
  Modal,
  Backdrop,
  Fade,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Toolbar,
  Paper,
  TableContainer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  AppBar,
  FormControl,
  InputLabel,
  Select,
  Alert,
} from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close';
import { addDays } from 'date-fns'
import MenuPopover from '../../components/MenuPopover'
/*import DatePicker from "react-datepicker";*/
import "react-datepicker/dist/react-datepicker.css"
import storage from 'utils/storage'

import { Formik, useField } from 'formik'
import * as yup from 'yup'
import dayjs from 'dayjs';
import discord_api from 'utils/api/discord'
import { useSnackbar } from 'notistack5'
import Bugsnag from '@bugsnag/js'
import useUser from 'utils/api/users'
import authUser from 'utils/api/auth'
import Clock from 'react-live-clock'
import { SocketContext } from 'utils/context/socket'
import { BreakOut, BreakIn, TimeIn, TimeOut, Completed, Loading } from 'components/timeButton'
import user_api from 'utils/api/users'
import Page from '../../components/Page'
import '../../_style.css'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { makeStyles } from '@material-ui/styles'
import { CSVLink } from 'react-csv'
import GetAppIcon from '@material-ui/icons/GetApp'
import HistoryIcon from '@mui/icons-material/History';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { WeeklySummary } from 'components/timekeepingHistory';
import { AllShift } from 'components/timekeepingHistory';
import MenuIcon from '@mui/icons-material/Menu';
import Numpad from 'components/authentication/login/Numpad'
import FourDigitDisplay from 'components/authentication/login/FourDigitDisplay'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { set } from 'lodash'
import OTPInput from 'react-otp-input'
import AccountChangeMPIN from 'components/account/AccountChangeMPIN';

const useStyles = makeStyles({
  customWidth: {
    maxWidth: "none",
    height: "500px"
  },
  customWidthHistory: {
    maxWidth: "none",
    height: "80%"
  }
})

// const MENU_OPTIONS = [
//   {
//     label: 'Home',
//     icon: homeFill,
//     linkTo: '/',
//   },
//   {
//     label: 'Profile',
//     icon: personFill,
//     linkTo: '/stores/profile',
//   },
//   {
//     label: 'Settings',
//     icon: settings2Fill,
//     linkTo: '#',
//   },
// ]

const validationSchema = yup.object({
  company: yup.string().max(100, 'Too long').required(),
})

const CustomTextField = ({ type, label, placeholder, InputProps, ...props }) => {
  const [field, meta] = useField(props)
  const errorText = meta.error && meta.touched ? meta.error : ''
  return (
    <TextField
      label={label}
      type={type}
      variant="standard"
      fullWidth
      margin="dense"
      required
      placeholder={placeholder}
      {...field}
      helperText={errorText}
      error={!!errorText}
      InputProps={InputProps}
    />
  )
}
// ----------------------------------------------------------------------
const moment = require('moment-timezone')
moment().tz('Asia/Manila').format()
const current_date = `${moment().tz('Asia/Manila').toISOString(true)}`
export default function AccountPopover({ user, onNavigate }) {
  const classes = useStyles()
  let today = new Date(current_date)
  const anchorRef = useRef(null)
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [checklistStatus, setChecklistStatus] = useState(true)
  const [openSchedule, setOpenSchedule] = useState(false)
  const [openPayslip, setOpenPayslip] = useState(false)
  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [checklists, setChecklists] = useState(["I testify that I am in front of the TS and management representative upon signing in. Also, I signify that I have:","Complete uniform.","Name plate.","Proper grooming.","Vaccination card."])
/*  const [checklist1, setChecklist1] = useState("Complete uniform.")
  const [checklist2, setChecklist2] = useState("Name plate.")
  const [checklist3, setChecklist3] = useState("Proper grooming.")
  const [checklist4, setChecklist4] = useState("Vaccination card.")*/
  const [openQR, setOpenQR] = useState(false)
  const [openChecklist, setOpenChecklist] = useState(false)
  const [coc, setCoC] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [value, setValue] = useState(null)
  const [validated, setValidated] = useState(false)
  const [openWorkmateTimein, setOpenWorkmateTimein] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [userState, setUser] = useState(user)
  const [currentDate, setCurrentDate] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [openHistory, setOpenHistory] = useState(false)
  const [openActiveUsers, setOpenActiveUsers] = useState(false)
  const [activeUserLoading, setActiveUserLoading] = useState(false)
  const socket = useContext(SocketContext)
  const [status, setStatus] = useState(null)
  const [link, setLink] = useState()
  const [qrUser, setQrUser] = useState({})
  const [users, setUsers] = useState([])
  const [userGetSchedule, setUserGetSchedule] = useState([])
  const [payrolls, setPayrolls] = useState([])
  const [activeUsers, setActiveUsers] = useState([])
  const [startDate, setStartDate] = useState(new Date());
  const [scheduleDate, setScheduleDate] = useState(new Date());
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedPayroll, setSelectedPayroll] = useState();
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedMonthIndex, setselectedMonthIndex] = useState(0);
  const [openVideoGuides, setOpenVideoGuides] = useState(false)
  const [videoFetched, setVedioFetched] = useState(false)
  const [openUpdateEmail, setOpenUpdateEmail] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [newEmail, setNewEmail] = useState(userState.email)
  const [videoList, setVideoList] = useState()
  const [urlPayslip, setUrlPayslip] = useState('')
  const [authCheck, setAuthCheck] = useState(false)
  const [authCheckEditCompany, setAuthCheckEditCompany] = useState(true)
  const [authCheckPass, setAuthCheckPass] = useState('');
  const [authCheckPassCompany, setAuthCheckPassCompany] = useState('');
  const [isPress, setIsPress] = useState({
    history: false,
    schedule: false,
    payslip: false,
    video: false,
    settings: false
  })
  const [location, setLocation] = useState({
    latitude: 0,
    longitude: 0,
  })
  const months = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December'
  ];
  const company = [
    '7star', 'Syzygy', 'Jasem', 'Admin'
  ];
  const [summaryPage, setSummaryPage] = useState(true)
  const [allShiftPage, setAllShiftPage] = useState(false)
  const checkAndroid = /Android/.test(navigator.userAgent)
  const checkIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent)
  const checkMac = /Macintosh/.test(navigator.userAgent)

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    setselectedMonthIndex(event.target.value + 1)
    setActiveUsers([])
  };
  const handlePayrollChange = (event) => {
    setSelectedPayroll(event.target.value);
  };
  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
    setActiveUsers([])
  };
  const handleExtractUsers = async () => {
    setActiveUserLoading(true)
    let formattedCompany = ""
    if (selectedMonthIndex === 0) {
      alert("Please input month")
    }
    else if (!selectedCompany) {
      alert("Please input company")
    }
    else {
      if (selectedCompany === "7star") {
        formattedCompany = "7-star"
      }
      else if (selectedCompany === "Syzygy") {
        formattedCompany = "syzygy"
      }
      else if (selectedCompany === "Admin") {
        formattedCompany = "Inhouse"
      }
      else {
        formattedCompany = "jasem"
      }
      const data = {
        company: formattedCompany,
        month: selectedMonthIndex
      }
      const result = await useUser.get_active_users(data)
      if (result.data.msg) {
        alert(result.data.msg)
        setActiveUserLoading(false)
      }
      else {
        const uniqueArray = result.data.filter(
          (item, index, self) => self.findIndex((i) => i.name === item.name) === index
        );
        setActiveUsers(uniqueArray)
      }

    }
    setActiveUserLoading(false)
  };

  useEffect(() => {
    if(selectedPayroll){

      handleGeneratePayslip()
    }
  }, [selectedPayroll])

  const getCsvData = () => {

    const csvData = []
    csvData.push([`Company: `, `${selectedCompany}`])
    csvData.push([`Month: `, `${months[selectedMonth]}`])
    csvData.push(['', '', '', '', '', ''])
    csvData.push(['', '', '', '', '', ''])
    csvData.push(['Store', 'Employee'])
    activeUsers.map(user => {
      csvData.push([user.store, user.name])
    })
    return csvData
  }
  const load = async () => {
    const token = await storage.getToken()
    if (!token) {
      await storage.remove()
      return navigate('/login')
    }
    const inuser = await user_api.get_user(jwt_decode(token)['id'])
    if (!inuser.ok) {
      Bugsnag.notify(inuser)
      await storage.remove()
      return navigate('/login')
    }
    await storage.storeUser(inuser.data)
    const local_user = await storage.getUser()
    if (!local_user) return enqueueSnackbar('Unable to proceed, Kindly Re-log again', { variant: 'warning' })
    const userState = JSON.parse(local_user)
    setUser(userState)
  }

  useEffect(() => {
    load()
  }, [])

  const handleClickOpenEdit = () => {
    setOpenEdit(true)
  }

  const handleCloseEdit = () => {
    setValidated(false)
    setOpenEdit(false)
    setAuthCheckPassCompany('')
    setAuthCheckEditCompany(true)
  }

  const handleClickOpenUpdateEmail = () => {
    setOpenUpdateEmail(true)
  }

  const handleCloseUpdateEmail = () => {
    setOpenUpdateEmail(false)
    setAuthCheck(false)
    setAuthCheckPass('')
  }

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setValidated(false)
    setOpen(false)
  }
  const handleOpenChecklist = async () => {
    const data = {
      "store": user.company
    }
    const result = await user_api.get_checklist(data)
    if(result.data.data !== "No records found") {
      if(result.data.data[0].toggle) {
        setChecklistStatus(true)
      }
      else {
        setChecklistStatus(false)
      }
      setChecklists(result.data.data[0].checklists)
    }
    else {
      setChecklists(["I testify that I am in front of the TS and management representative upon signing in. Also, I signify that I have:","Complete uniform.","Name plate.","Proper grooming.","Vaccination card."])
    }
    setOpenChecklist(true)
  }
  const handleCloseChecklist = () => {
    setOpenChecklist(false)
  }
  const handleSignOut = async (e) => {
    e.preventDefault()
    await storage.remove()
    navigate(`/login`)
  }

  const handleOpenWorkmateTimein = async () => {
    setOpenWorkmateTimein(true)
  }

  const handleCloseWorkmateTimein = () => {
    setValue(null)
    setOpenWorkmateTimein(false)
    setValidated(false)
  }

  const handleSubmitChecklist = async () => {
    setActiveUsers(true)
    if(!checklists[0] || !checklists[1] || !checklists[2] || !checklists[3]) {
      alert("Please fill up all the fields")
    }
    else {
      const data = {
        "store": user.company,
        "checklists": checklists,
        "status": checklistStatus
      }
      const result = await user_api.post_checklist(data)
      if (result.data.msg === "Success") {
        alert("Checklist save")
        setOpenChecklist(false)
      }
      else {
        alert(result.data.msg)
      }
      setActiveUsers(false)  
    }
    
  }

  const handleScan = async (res) => {
    if (res) {
      setValue(res)
      const result = await user_api.get_user(res)
      setQrUser(result.data)
      handleStatus(res)
    }
  }
  const handleError = (err) => {
    console.error(err)
  }

  const handleGeneratePayslip = async () => {
    setActiveUserLoading(true)
    if (!selectedPayroll) {
      alert("Please input cutoff date")
      setActiveUserLoading(false)
    }
    else {
      setUrlPayslip(`https://payroll-live.sparkletimekeeping.com/payroll/public/payslip-api/${userState._id}/${selectedPayroll}`)
      setActiveUserLoading(false)  
    }

  }

  const saveGeneratedPayslip = async () => {
    if(!urlPayslip){
      alert("Please select a payslip")
      return
    }
    const link = document.createElement('a');
    link.href = urlPayslip;
    link.setAttribute('download', `payslip-${selectedPayroll}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handleStatus = async (_id) => {
    if (!_id) {
      setLoading(false)
      return navigate('/login')
    }

    setLoading(true)
    await geolocation()
    const result = await user_api.get_user_status(_id)

    if (!result.ok) {
      setLoading(false)
      Bugsnag.notify(result)
      setStatus(null)
    }

    if (!result || !result.data) {
      setStatus(null)
      return setLoading(false)
    }

    if (!result.data) return setLoading(false)
    let { status, date } = result.data[0] // get the last data

    let record_date = new Date(date)

    // if (record_date.getDate() === today.getDate() && record_date.getUTCMonth() + 1 === today.getUTCMonth() + 1) {
    setStatus(status)
    setCurrentDate(date)
    // } else {
    if (record_date.getDate() !== today.getDate() && status === 'time-out') {
      setStatus(null)
      setLoading(false)
      return
    }
    // }

    switch (status) {
      case 'time-in':
        setStatus('Time in')
        break
      case 'time-out':
        setStatus('Time out')
        break
      case 'break-in':
        setStatus('Break in')
        break
      case 'break-out':
        setStatus('Break out')
        break
      default:
        setStatus(null)
        break
    }

    setLoading(false)
  }

  const ButtonMemo = React.memo(ButtonContainerMemo)

  function ButtonContainerMemo() {
    return <RenderButtonStatus />
  }

  function handleCheckboxChange(e) {
    setValidated(e.target.checked)
  }

  const geolocation = async () => {
    await navigator.geolocation.watchPosition(function (position) {
      setLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      })
    })

    if (!location) return false
    return location
  }

  const handleUpdateStatus = async (status) => {
    try {
      setLoading(true)
      if (!status) return setLoading(false)
      await geolocation()

      if (!location.latitude && !location.longitude) return setLoading(false)
      let formatStatus = status.replace(' ', '-').toLowerCase()
      let previous = null
      let dataDate = new Date(currentDate)
      if (dataDate.getDate() !== today.getDate() || status !== 'time-in') {
        previous = currentDate
      }
      let workmate = user.displayName
      const result = await user_api.post_user_workmate_status(formatStatus, location, value, previous, workmate)
      if (!result.ok) {
        setLoading(false)
        Bugsnag.notify(result)
        return enqueueSnackbar(result.data.msg, { variant: 'error' })
      }

      switch (result.data.status) {
        case 'time-in':
          setStatus('Time out')
          break
        case 'time-out':
          setStatus('Time in')
          break
        case 'break-in':
          setStatus('Break out')
          break
        case 'break-out':
          setStatus('Break in')
          break
        default:
          break
      }

      socket.emit('update_status', status)
      enqueueSnackbar(`${status} Success`, { variant: 'success' })
      setLoading(false)
      handleStatus(value)
    } catch (error) {
      console.log(error)
    }
  }

  const RenderButtonStatus = () => {
    const cur_status = status

    switch (cur_status) {
      case null:
        return <TimeIn request={handleUpdateStatus} />
      case 'Time in':
        return (
          <>
            <TimeOut request={handleUpdateStatus} width="215px" current_status={cur_status} />
            <BreakIn request={handleUpdateStatus} width="215px" current_status={cur_status} />
            <span> for {` ${new Date(currentDate).toDateString()} `}</span>
          </>
        )
      case 'Break in':
        return (
          <>
            <BreakOut request={handleUpdateStatus} width="215px" current_status={cur_status} />
            <TimeOut request={handleUpdateStatus} width="215px" current_status={cur_status} />
            <span> for {` ${new Date(currentDate).toDateString()} `}</span>
          </>
        )
      case 'Break out':
        return (
          <>
            <TimeOut request={handleUpdateStatus} width="215px" current_status={cur_status} />
            <BreakIn request={handleUpdateStatus} width="215px" current_status={cur_status} />
            <span> for {` ${new Date(currentDate).toDateString()} `}</span>
          </>
        )
      case 'Time out':
        return (
          <>
            <Completed currentDate={currentDate} />
            <button onClick={() => setStatus(null)}>Ok</button>
          </>
        )
      default:
        return <Loading />
    }
  }

  const handleGenerateQR = (e) => {
    setOpenQR(true);
    setId(user._id);
    setName(user.displayName);
  }

  const handleCloseQR = () => setOpenQR(false);

  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas.toDataURL("image/png");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${name}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleUpdateLocation = async () => {
    await geolocation()
    if (location.longitude !== 0 || location.latitude !== 0) {
      const data = {
        long: location.longitude,
        lat: location.latitude
      }
      const result = await user_api.patch_user_location(user._id, data);
      if (result.status === 200) {
        alert("Location update successful");
      }
    } else {
      alert("Please turn on your location");
    }
  }

  const renderTime = (_time) => {
    let _date = new Date(_time)
    var hours = _date.getHours()
    var minutes = _date.getMinutes()
    var ampm = hours >= 12 ? 'pm' : 'am'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes
    var strTime = hours + ':' + minutes + ' ' + ampm
    return strTime
  }

  const filterByStatus = (_data, type) => {
    let _d = _data.filter((_d) => (_d.status === type ? _d : ''))
    if (_d.length > 0) {
      _d = _d[0]
    }
    if (_d) {
      if (typeof (_d.time) === "string") {
        return {
          time: _d.time === undefined ? 'n/a' : _d.time,
          location: _d.address === undefined ? 'n/a' : _d.address,
          workmate: _d.workmate === undefined ? '' : 'Workmate( ' + _d.workmate + ' )'
        }
      }
      else {
        return {
          time: _d.time === undefined ? 'n/a' : renderTime(_d.time),
          location: _d.address === undefined ? 'n/a' : _d.address,
          workmate: _d.workmate === undefined ? '' : 'Workmate( ' + _d.workmate + ' )'
        }
      }
    } else {
      return '-'
    }
  }

  const handleOpenHistory = async () => {
    if (userState._id === undefined) {
      load()
      alert("Something went wrong please try again later")
    }
    else {
      setHistoryLoading(true)
      const result = await user_api.get_limited_users(userState._id)
      setUsers(result.data)
      setOpenHistory(true)
      setHistoryLoading(false)
    }
  }

  const handleOpenActiveUsers = async () => {
    if (userState._id === undefined) {
      load()
      alert("Something went wrong please try again later")
    }
    else {
      const result = await user_api.get_limited_users(userState._id)
      setUsers(result.data)
      setOpenActiveUsers(true)
    }

  }

  const handleOpenVideoGuides = async () => {
    setOpenVideoGuides(true)
    setIsPress({
      history: false,
      schedule: false,
      payslip: false,
      video: false,
      settings: false
    })
    const company = JSON.parse(await storage.getUser()).company
    // setVedioFetched(true)
    // setVideoList(videoData)

    const getVedioResult = await user_api.get_videos_tutorial(company)
    if(getVedioResult.status === 200){
      setVideoList(getVedioResult.data.body)
      setVedioFetched(true)
    }
  }

  const handleViewYoutube = (viewId) =>{
    window.open(`https://www.youtube.com/watch?v=${viewId}`)
  }
  const handleCloseVideoGuides = () =>{
    setOpenVideoGuides(false)
  }

  const handleOpenSettings = () => {
    window.alert("Under development")
    setIsPress({
      history: false,
      schedule: false,
      payslip: false,
      video: false,
      settings: false
    })
  }

  const handleCloseActiveUsers = async () => {
    setOpenActiveUsers(false)
    setActiveUsers([])
  }
  const handleOpenPayslip = async () => {
    let payroll = []
    if (userState._id === undefined) {
      load()
      alert("Something went wrong please try again later")
    }
    else {
      setLoading(true)
      setPayslipLoading(true)
      const user = await user_api.get_user(userState._id)
      alert("Getting payslip data please wait")
      const data = {
        id: user.data._id,
        store: user.data.company
      }
      const result = await user_api.get_payslip_info(data)
      if (result.data.record.data.length > 0) {
        result.data.record.data.map(data => {
          payroll.push({ "id": data.id, "from": data.payroll_from, "to": data.payroll_to })
        })
        setPayrolls(payroll)
        setOpenPayslip(true)
      }
      else {
        setPayrolls([])
        setOpenPayslip(true)
      }
      setLoading(false)
      setPayslipLoading(false)

    }

  }
  const handleClosePayslip = async () => {
    setOpenPayslip(false)
    setActiveUsers([])
    setUrlPayslip('')
    setIsPress({
      history: false,
      schedule: false,
      payslip: false,
      video: false,
      settings: false
    })
  }
  const handleCloseHistory = async () => {
    setOpenHistory(false)
    setIsPress({
      history: false,
      schedule: false,
      payslip: false,
      video: false,
      settings: false
    })
  }

  const handleChangeDate = async (date) => {
    const fomattedDate = moment(date).format('YYYY-MM-DD')
    setStartDate(date)
    const result = await user_api.get_users_bydate(userState._id, fomattedDate)
    setUsers(result.data)
  }

  const handleChangeScheduleDate = async (date) => {
    const fomattedDate = moment(date).format('YYYY-MM-DD')
    setScheduleDate(date)
    const data = {
      id: userState._id,
      date: moment(addDays(date, 0)).format('L'),
    }
    const result = await user_api.get_specific_schedule_with_date(data)
    setUserGetSchedule(result.data)
  }

  const handleOpenCoc = async () => {
    setCoC(true)
  }

  const handleCloseCoC = async () => {
    setCoC(false)
  }

  const handleUpdateEmail = async () => {
    try {
      setSubmitting(true);
       const records = {
        "id": userState._id,
        "email": newEmail
      };
      const result = await useUser.update_email(records);
      if(result.status === 200) {
        alert("Email successfuly updated please relogin using your new email")
        await storage.remove()
        navigate(`/login`)
      }
      else {
        alert(result.data.msg)
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setSubmitting(false);
    }
  }

  const handleAuthUpdateEmail = async () => {
    try {
      setSubmitting(true);
       const data = {
        email: userState.email,
        pass: authCheckPass
      }

      const result = await authUser.authenticate_check(data);
      if(result.data.success) {
        handleUpdateEmail()
        return
      }
      alert("Failed to authorize")
    } catch (error) {
      console.error("An error occurred:", error);
      alert("Something wrong happened, try again later")
    } finally {
      setSubmitting(false);
    }
  }

  const handleAuthUpdateCompany = async () => {
    try {
      setSubmitting(true);
       const data = {
        email: userState.email,
        pass: authCheckPassCompany
      }

      const result = await authUser.authenticate_check(data);
      if(result.data.success) {
        setAuthCheckEditCompany(false)
        return
      }
      alert("Failed to authorize")
    } catch (error) {
      console.error("An error occurred:", error);
      alert("Something wrong happened, try again later")
    } finally {
      setSubmitting(false);
    }
  }

  const handleOpenSchedule = async () => {
    if (userState._id === undefined) {
      load()
      alert("Something went wrong please try again later")
    }
    else {
      setScheduleLoading(true)
      const result = await user_api.get_schedule(userState._id)
      if (result.data.length <= 0) {
        alert("No schedule found")
        setUserGetSchedule([])
      }
      else {
        setUserGetSchedule(result.data)
      }
      setOpenSchedule(true)
      setScheduleLoading(false)
    }
  }

  const handleCloseSchedule = async () => {
    setOpenSchedule(false)
    setIsPress({
      history: false,
      schedule: false,
      payslip: false,
      video: false,
      settings: false
    })
  }

  const handleUpdateCoc = async () => {
    setLoading(true)
    if (userState.company === undefined) {
      load()
      alert("Something went wrong please try again")
      setLoading(false)
    }
    else {
      const data = {
        company: userState.company,
        link: link
      }
      const result = await user_api.set_company_coc(data)
      if (result.data.success) {
        alert(result.data.msg)
        setCoC(false)
      }
      else {
        alert(result.data.msg)
      }
    }
    setLoading(false)
  }

  const handleisPressButton = (data) => {
    const tabs = ['history', 'schedule', 'payslip', 'video', 'settings'];

    setIsPress(
      tabs.reduce((acc, tab) => ({ ...acc, [tab]: tab === data }), {})
    );
  }

  const handleSwitchPage = (data) => {
    if (data === 0) {
      setSummaryPage(true)
      setAllShiftPage(false)
    } else {
      setSummaryPage(false)
      setAllShiftPage(true)
    }
  }

  const handleNavigate = (page) => {
    switch (page) {
      case 'profile':
        onNavigate()
        navigate('app/profile')
        break;
      case 'history':
        handleOpenHistory();
        handleisPressButton("history");
        break;
      case 'schedule':
        handleOpenSchedule();
        handleisPressButton("schedule");
        break;
      case 'payslip':
        handleOpenPayslip();
        handleisPressButton("payslip");
        break;
      case 'video':
        handleOpenVideoGuides();
        handleisPressButton("video");
        break;
      default:
        break;
    }
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [historyLoading, setHistoryLoading] = useState(false)
  const [scheduleLoading, setScheduleLoading] = useState(false)
  const [payslipLoading, setPayslipLoading] = useState(false)

  return (
    <>
        {
          userState.role === 4 ?
          <>
          <ListItem>
            <ListItemButton onClick={() => navigate('/')} sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Box sx={{display: 'flex', alignItems: 'center'}}>
                <ListItemIcon>
                  <Icon icon="material-symbols:home-outline" style={{ fontSize: '1.2rem' }} />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </Box>
              <ListItemIcon>
                <Icon icon="mdi-light:chevron-right" style={{ fontSize: '2rem' }} />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => navigate('/stores/profile')} sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Box sx={{display: 'flex', alignItems: 'center'}}>
                <ListItemIcon>
                  <Icon icon="mdi:account-outline" style={{ fontSize: '1.2rem' }} />
                </ListItemIcon>
                <ListItemText primary="Profile" />
              </Box>
              <ListItemIcon>
                <Icon icon="mdi-light:chevron-right" style={{ fontSize: '2rem' }} />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          </>
          :
          ''
        }
        {userState.role >= 3
          ?
          <>
            <ListItem>
              <ListItemButton onClick={handleClickOpenEdit} sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <ListItemIcon>
                    <Icon icon="mdi:pencil" style={{ fontSize: '1.2rem' }} />
                  </ListItemIcon>
                  <ListItemText primary="Edit Company" />
                </Box>
                <ListItemIcon>
                  <Icon icon="mdi-light:chevron-right" style={{ fontSize: '2rem' }} />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={handleClickOpenUpdateEmail} sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <ListItemIcon>
                    <Icon icon="mdi:email-edit" style={{ fontSize: '1.2rem' }} />
                  </ListItemIcon>
                  <ListItemText primary="Update Email" />
                </Box>
                <ListItemIcon>
                  <Icon icon="mdi-light:chevron-right" style={{ fontSize: '2rem' }} />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={handleOpenActiveUsers} sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <ListItemIcon>
                    <Icon icon="mdi:account-group" style={{ fontSize: '1.2rem' }} />
                  </ListItemIcon>
                  <ListItemText primary="Generate Active Users" />
                </Box>
                <ListItemIcon>
                  <Icon icon="mdi-light:chevron-right" style={{ fontSize: '2rem' }} />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            <ListItem hidden>
              <ListItemButton onClick={handleUpdateLocation} sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <ListItemIcon>
                    <Icon icon="mdi:map-marker" style={{ fontSize: '1.2rem' }} />
                  </ListItemIcon>
                  <ListItemText primary="Update Location" />
                </Box>
                <ListItemIcon>
                  <Icon icon="mdi-light:chevron-right" style={{ fontSize: '2rem' }} />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={handleOpenCoc} sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <ListItemIcon>
                    <Icon icon="mdi:file-upload" style={{ fontSize: '1.2rem' }} />
                  </ListItemIcon>
                  <ListItemText primary="Upload COC" />
                </Box>
                <ListItemIcon>
                  <Icon icon="mdi-light:chevron-right" style={{ fontSize: '2rem' }} />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={handleOpenChecklist} sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <ListItemIcon>
                    <Icon icon="mdi:clipboard-check" style={{ fontSize: '1.2rem' }} />
                  </ListItemIcon>
                  <ListItemText primary="Set Checklist" />
                </Box>
                <ListItemIcon>
                  <Icon icon="mdi-light:chevron-right" style={{ fontSize: '2rem' }} />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </>
          :
          ''
        }
        {userState.role === 0 || user.role === 0
          ?
          <List>
            <ListItem>
              <ListItemButton onClick={() => handleNavigate('profile')} sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <ListItemIcon>
                    <Icon icon="iconamoon:profile" style={{ fontSize: '1.2rem' }} />
                  </ListItemIcon>
                  <ListItemText primary="My Profile" />
                </Box>
                <ListItemIcon>
                  <Icon icon="mdi-light:chevron-right" style={{ fontSize: '2rem' }} />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={handleOpenHistory} sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <ListItemIcon>
                    <Icon icon="mdi:clipboard-text-history-outline" style={{ fontSize: '1.2rem' }} />
                  </ListItemIcon>
                  <ListItemText primary="My History" />
                </Box>
                <ListItemIcon>
                  <Icon icon="mdi-light:chevron-right" style={{ fontSize: '2rem' }} />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={handleOpenSchedule} sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <ListItemIcon>
                    <Icon icon="mdi:calendar-month-outline" style={{ fontSize: '1.2rem' }} />
                  </ListItemIcon>
                  <ListItemText primary="My Schedule" />
                </Box>
                <ListItemIcon>
                  <Icon icon="mdi-light:chevron-right" style={{ fontSize: '2rem' }} />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={handleOpenPayslip} sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <ListItemIcon>
                    <Icon icon="fluent:receipt-money-20-regular" style={{ fontSize: '1.2rem' }} />
                  </ListItemIcon>
                  <ListItemText primary="View Payslip" />
                </Box>
                <ListItemIcon>
                  <Icon icon="mdi-light:chevron-right" style={{ fontSize: '2rem' }} />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={handleOpenVideoGuides} sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <ListItemIcon>
                    <Icon icon="octicon:question-24" style={{ fontSize: '1.2rem' }} />
                  </ListItemIcon>
                  <ListItemText primary="Video Guides" />
                </Box>
                <ListItemIcon>
                  <Icon icon="mdi-light:chevron-right" style={{ fontSize: '2rem' }} />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </List>
          :
          ''
        }
        {userState.role === 1
          ?
          <>
            <ListItem>
              <ListItemButton onClick={() => navigate('/')} sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <ListItemIcon>
                    <Icon icon="material-symbols:home-outline" style={{ fontSize: '1.2rem' }} />
                  </ListItemIcon>
                  <ListItemText primary="Home" />
                </Box>
                <ListItemIcon>
                  <Icon icon="mdi-light:chevron-right" style={{ fontSize: '2rem' }} />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => navigate('/stores/profile')} sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <ListItemIcon>
                    <Icon icon="mdi:account-outline" style={{ fontSize: '1.2rem' }} />
                  </ListItemIcon>
                  <ListItemText primary="Profile" />
                </Box>
                <ListItemIcon>
                  <Icon icon="mdi-light:chevron-right" style={{ fontSize: '2rem' }} />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={handleClickOpenEdit} sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <ListItemIcon>
                    <Icon icon="mdi:company" style={{ fontSize: '1.2rem' }} />
                  </ListItemIcon>
                  <ListItemText primary="Edit Company" />
                </Box>
                <ListItemIcon>
                  <Icon icon="mdi-light:chevron-right" style={{ fontSize: '2rem' }} />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={handleClickOpenUpdateEmail} sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <ListItemIcon>
                    <Icon icon="mdi:email-outline" style={{ fontSize: '1.2rem' }} />
                  </ListItemIcon>
                  <ListItemText primary="Update Email" />
                </Box>
                <ListItemIcon>
                  <Icon icon="mdi-light:chevron-right" style={{ fontSize: '2rem' }} />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            <ListItem hidden>
              <ListItemButton onClick={handleUpdateLocation} sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <ListItemIcon>
                    <Icon icon="mdi:map-marker-outline" style={{ fontSize: '1.2rem' }} />
                  </ListItemIcon>
                  <ListItemText primary="Update Location" />
                </Box>
                <ListItemIcon>
                  <Icon icon="mdi-light:chevron-right" style={{ fontSize: '2rem' }} />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={handleOpenCoc} sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <ListItemIcon>
                    <Icon icon="mdi:file-upload-outline" style={{ fontSize: '1.2rem' }} />
                  </ListItemIcon>
                  <ListItemText primary="Upload COC" />
                </Box>
                <ListItemIcon>
                  <Icon icon="mdi-light:chevron-right" style={{ fontSize: '2rem' }} />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={handleOpenChecklist} sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <ListItemIcon>
                    <Icon icon="mdi:checklist" style={{ fontSize: '1.2rem' }} />
                  </ListItemIcon>
                  <ListItemText primary="Set Checklist" />
                </Box>
                <ListItemIcon>
                  <Icon icon="mdi-light:chevron-right" style={{ fontSize: '2rem' }} />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </>
          :
          ''
        }

        <Divider sx={{ my: 1 }} />
        <ListItem>
          <ListItemButton onClick={handleSignOut} sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <ListItemIcon>
                <Icon icon="mdi:logout" style={{ fontSize: '1.2rem' }} />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </Box>
            <ListItemIcon>
              <Icon icon="mdi-light:chevron-right" style={{ fontSize: '2rem' }} />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
      <Dialog open={openEdit} onClose={handleCloseEdit}>
        <DialogTitle>Update company</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseEdit}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
            <DialogContentText>
              Kindly make sure to add the exact name of the company/store/branch from your manager. Only use this with the
              instruction of your admin.
            </DialogContentText>
          {authCheckEditCompany ? (
            <>
              <DialogContentText sx={{my: 2}}>
                Input your password to continue.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="password"
                label="Password"
                type="password"
                fullWidth
                variant="standard"
                value={authCheckPassCompany}
                onChange={(e) => {
                  setAuthCheckPassCompany(e.target.value)
                }}
              />
              <DialogActions>
                <Button onClick={handleCloseEdit}>Cancel</Button>
                <Button onClick={handleAuthUpdateCompany} disabled={submitting}>
                  {submitting ? <CircularProgress /> : 'Next'}
                </Button>
              </DialogActions>
            </>
          ) : (
            <>
              <Formik
                initialValues={{
                  company: `${userState.company}`,
                }}
                validationSchema={validationSchema}
                onSubmit={async (data) => {
                  let result
                  if (userState.role === 4 || userState.role === 1) {
                    result = await useUser.patch_store_onboard({ ...userState, company: data.company, role: userState.role, prev: userState.company }, userState._id)
                  }
                  else {
                    result = await useUser.patch_store_onboard({ ...userState, company: data.company }, userState._id)
                  }
                  result.data.token = userState.token

                  await storage.storeUser(result.data);
                  await storage.storeToken(result.data.token)

                  if (!result.ok) {
                    Bugsnag.notify(result)
                    return enqueueSnackbar('Unable to submit your request for assitance', { variant: 'error' })
                  }
                  else {
                    /*let params = JSON.stringify({
                      username: 'Captain Sparkle',
                      avatar_url:
                        'https://www.sparkles.com.ph/static/2629bb8535ba6ae5406fc9385dadc2e0/497c6/Spark--noodles.png',
                      content: ` Time In Log - Company update \n**from:**\n ${userState.displayName} - ${userState._id} \n**Company:**\n ${userState.company} **->** ${data.company}`,
                    })
                    await discord_api.send_message(params)*/
                    setUser({ ...userState, company: data.company })
                    handleCloseEdit()
                    return enqueueSnackbar('Company updated.', { variant: 'success' })
                  }


                }}
              >
                {({ values, handleChange, handleSubmit, isSubmitting }) => (
                  <form onSubmit={handleSubmit}>
                    <CustomTextField
                      autoFocus
                      margin="dense"
                      name="company"
                      label="Company"
                      fullWidth
                      variant="standard"
                      onChange={handleChange}
                    />

                    <DialogActions>
                      <Button onClick={handleCloseEdit}>Cancel</Button>
                      <Button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? <CircularProgress /> : 'Update'}
                      </Button>
                    </DialogActions>
                  </form>
                )}
              </Formik>
            </>
          )}
        </DialogContent>
      </Dialog>
      {/*Work time-in dialog box*/}
      <Dialog open={openWorkmateTimein} onClose={handleCloseWorkmateTimein}>
        <DialogTitle>Workmate Scan?</DialogTitle>
        <DialogContent>
          {value && validated ? (
            <Page title="Dashboard | Time in">
              <Container maxWidth="sm">
                <Box sx={{ pb: 5 }} style={{ width: '100%' }}>
                  <span>Welcome, </span>
                  <Typography variant="h4" style={{ color: '#000' }}>
                    {qrUser.displayName ? qrUser.displayName : `${qrUser.firstName} ${qrUser.lastName}`}
                  </Typography>
                </Box>

                <Grid

                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justify="center"
                  style={{ minHeight: '60vh' }}
                >
                  <>
                    <Grid item xs={10}>
                      <Typography variant="h6" component="h2" style={{ color: '#7f8c8d', fontWeight: '400' }}>
                        Current Time <Clock format={'LL'} ticking={true} timezone={'Asia/Manila'} />
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="h3" component="h1">
                        <Clock format={'HH:mm:ss'} ticking={true} timezone={'Asia/Manila'} />
                      </Typography>
                    </Grid>
                  </>

                  {isLoading ? <Loading /> : <ButtonMemo />}
                </Grid>
              </Container>
            </Page>
          ) : (
            <>
              {!validated ? (
                <FormControlLabel style={{ width: '250px', heigth: '200px' }} control={<Checkbox />} onChange={(e) => handleCheckboxChange(e)} label="By checking this you guarantee the presence of your workmate in the vicinity using this time-in." />
              ) : (
                <>
                  <QrReader
                    delay={3000}
                    onError={handleError}
                    onScan={handleScan}
                    // chooseDeviceId={()=>selected}
                    style={{ width: '250px', heigth: '200px' }}
                  // className={'qrScanner'}
                  />
                  <DialogTitle style={{ display: "flex", justifyContent: "center" }}>No Data found</DialogTitle>
                </>
              )
              }

            </>
          )}
        </DialogContent>
      </Dialog>
      <Dialog open={openHistory} onClose={handleCloseHistory} maxWidth="xl" classes={{ paperScrollPaper: classes.customWidthHistory }}>
        <AppBar sx={{ position: "relative",  backgroundColor: '#2194F3', }}>
          <DialogTitle sx={{color:'white'}}>Time keeping history</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseHistory}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </AppBar>
        <DialogContent>
          {/* <DatePicker selected={startDate} onChange={(date:Date) => handleChangeDate(date)} /> */}
          {/* <div style={{ paddingBottom: ".5em" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>

              <MobileDatePicker
                label="Choose Date"
                value={startDate}
                minDate={new Date('2017-01-01')}
                renderInput={(params) => <TextField {...params} />}
                onChange={(date) => handleChangeDate(date)}
                disableTextEditor
              />
            </LocalizationProvider>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 1000 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Proccess ID</TableCell>
                  <TableCell align="right">Date</TableCell>
                  <TableCell align="right">Time-in</TableCell>
                  <TableCell align="right">Break-in</TableCell>
                  <TableCell align="right">Break-out</TableCell>
                  <TableCell align="right">Time-out</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.length > 0
                  ?
                  <>
                    {users.map((row) => (

                      <TableRow
                        key={row._id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {row._id}
                        </TableCell>
                        <TableCell align="right">{moment(row.date).format('MMMM-DD-YYYY')}</TableCell>
                        <TableCell align="right">{filterByStatus(row.record, 'time-in').time + " - " + filterByStatus(row.record, 'time-in').workmate}</TableCell>
                        <TableCell align="right">{filterByStatus(row.record, 'break-in').time + " - " + filterByStatus(row.record, 'break-in').workmate}</TableCell>
                        <TableCell align="right">{filterByStatus(row.record, 'break-out').time + " - " + filterByStatus(row.record, 'break-out').workmate}</TableCell>
                        <TableCell align="right">{filterByStatus(row.record, 'time-out').time + " - " + filterByStatus(row.record, 'time-out').workmate}</TableCell>
                      </TableRow>
                    ))}
                  </>
                  :
                  ''
                }

              </TableBody>
            </Table>
          </TableContainer> */}
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {summaryPage && (<WeeklySummary />)}
              {allShiftPage && (<AllShift />)}
            </>
          )}
        </DialogContent>
        <AppBar sx={{ position: "relative", top: "auto", bottom: 0 }}>
          {/* Footer content */}
          <Toolbar sx={{
            padding: '10px',
            backgroundColor: '#2194F3',
            boxShadow: '0px -2px 5px 0px rgba(0, 0, 0, 0.1)',
          }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                width:'100%'
              }} >
              <Box
                onClick={() => handleSwitchPage(0)}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <HistoryIcon style={{ fontSize:summaryPage? 45 : 30, color: summaryPage ? 'white' : '#d4d4d4' }} />
                <Typography variant='caption' sx={{ color:summaryPage? 'white':'#d4d4d4' }}> Weekly Summary </Typography>
              </Box>
              <Box
                onClick={() => handleSwitchPage(1)}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <DateRangeIcon style={{ fontSize: allShiftPage? 45:30, color: allShiftPage ? 'white' : '#d4d4d4' }} />
                <Typography variant="caption" sx={{ color: allShiftPage? 'white':'#d4d4d4' }}> All My Shifts </Typography>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      </Dialog>
      <Dialog open={openSchedule} onClose={handleCloseSchedule} maxWidth="sm" classes={{ paperScrollPaper: classes.customWidth }}>
        <AppBar sx={{ position: "relative",  backgroundColor: '#2194F3', }}>
          <DialogTitle sx={{color:'white'}} >Schedule</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseSchedule}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </AppBar>
        <DialogContent>
          {/* <DatePicker selected={startDate} onChange={(date:Date) => handleChangeDate(date)} /> */}
          <Box sx={{display:'flex', justifyContent:'flex-end', paddingBottom: ".5em"}} gap={1}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Choose Date"
                value={scheduleDate}
                minDate={new Date('2017-01-01')}
                renderInput={(params) => <TextField {...params} />}
                onChange={(date) => handleChangeScheduleDate(date)}
                sx={{flexGrow:2}}
              />
            </LocalizationProvider>
            <Button onClick={handleOpenSchedule} variant="contained" sx={{flexGrow:1}}>
            Show All
            </Button>
          </Box>
          {/* <TableContainer component={Paper}>
            <Table sx={{ minWidth: 1000 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="left">Start Shift</TableCell>
                  <TableCell align="left">End Shift</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userGetSchedule.length > 0
                  ?
                  userGetSchedule.map(d => {
                    return (
                      <>
                        <TableRow
                          key={d._id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="left">{moment(new Date(d.date)).format('MMMM-DD-YYYY')}</TableCell>
                          <TableCell align="left">{d.from}</TableCell>
                          <TableCell align="left">{d.to}</TableCell>


                        </TableRow>
                      </>
                    )
                  })
                  :
                  ''
                }
              </TableBody>
            </Table>
          </TableContainer> */}
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
              <CircularProgress />
            </Box>
          ) : (
            userGetSchedule.length > 0
              ?
              userGetSchedule.map(d => {
                return (
                  <>
                    <br/>
                    <Box>
                      <Box
                      border={1}
                      borderRadius={2}
                      sx={{
                          overflowX: 'auto',
                          display: 'flex',
                          gap: '.5rem',
                          padding: '.5rem',
                          margin: 'auto',
                          justifyContent: 'center',
                          width: '100%'
                      }}>
                    <Box sx={{ alignContent: 'center', textAlign: 'center' }}>
                        <Typography variant='caption' >Position</Typography>
                        <br/>
                        <Typography variant="caption"  sx={{ fontSize: '.6rem' }}>{d.position}</Typography>
                    </Box>      
                    
                    <Box sx={{ alignContent: 'center', textAlign: 'center' }}>
                        <Typography variant='caption' >Start Shift</Typography>
                        <br/>
                        <Typography variant="caption"  sx={{ fontSize: '.6rem' }}>{d.from}</Typography>
                    </Box>
                    <Box sx={{ alignContent: 'center', textAlign: 'center' }}>
                        <Typography variant='caption' >End Shift</Typography>
                        <br/>
                        <Typography variant="caption"  sx={{ fontSize: '.6rem' }}>{d.to}</Typography>
                    </Box>
                    <Box sx={{ alignContent: 'center', textAlign: 'center' }}>
                        <Typography variant='caption'>Date</Typography>
                        <br/>
                        <Typography variant="caption" sx={{ fontSize: '.6rem' }}>{moment(new Date(d.date)).format('MMMM-DD-YYYY')}</Typography>   
                    </Box>
                </Box>
            </Box>
                  </>
                )
              })
              :
              ''
          )}
        </DialogContent>
      </Dialog>
      <Dialog open={coc} onClose={handleCloseCoC} fullWidth>
        <DialogTitle>Update CoC?</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseCoC}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText>
            Paste the upload link of COC.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="link"
            label="Link"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setLink(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCoC}>Cancel</Button>
          <Button type="button" onClick={handleUpdateCoc}>
            {isLoading ? <CircularProgress /> : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openPayslip}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openPayslip}>
          <Box sx={urlPayslip ? {...style, height: '90%', width: '90%'} : {...style}}>
            <Grid container sx={urlPayslip ? { pl: 2, height:'100%', width: '100%', overflow: (checkIOS || checkMac) ? 'auto' : 'hidden'} : { pl: 2 }} >
              <Typography variant="h4" style={{ color: '#000', margin: "0px 0px 17px 0px" }}>
                Available Payslip
              </Typography>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select Cutoff</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedPayroll}
                  label="Select month"
                  onChange={handlePayrollChange}
                  fullWidth
                >
                  {payrolls.length > 0
                    ?
                    payrolls.map((data, index) => (
                      <MenuItem value={data.id}><b>{data.from}</b> - to - <b>{data.to}</b></MenuItem>
                    ))
                    :
                    <MenuItem>No Available Payslip</MenuItem>
                  }

                </Select>
              </FormControl>
              {activeUserLoading || isLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '200px' }}>
                  <CircularProgress />
                </Box>
              ) : (
                urlPayslip && (
                  <Box sx={{ my: 2, overflow: 'hidden', width: '100%', height: (checkIOS || checkMac) ? '60%' : '65%' }}>
                    {!checkAndroid ? (
                      <iframe src={urlPayslip} style={{ zoom: '100%',  width: '100%', height: '100%' }}></iframe>
                    ) : (
                      <iframe src={`https://docs.google.com/gview?url=${urlPayslip}&embedded=true`} width="100%" height="100%" style={{ zoom: '100%' }}></iframe>
                    )}
                  </Box>
                )
              )}
              {(checkIOS || checkMac) && urlPayslip && (
                <Alert severity="info">{checkMac ? 'Hover over the payslip bottom center and click the download icon to save' : 'To save, Press and hold for 2 seconds on the payslip and click "Save to photos"'}</Alert>
              )}
              <Box sx={{ mt: 1, display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <Button variant="contained" color="primary" onClick={handleClosePayslip} sx={{ flex: 1, mr: 1 }}>
                  Back
                </Button>
                {checkAndroid && (
                  <Button variant="contained" color="success" onClick={saveGeneratedPayslip} sx={{ flex: 1, ml: 1 }}>
                    Save
                  </Button>
                )}
              </Box>
              
            </Grid>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openActiveUsers}
        onClose={handleCloseActiveUsers}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openActiveUsers}>
          <Box sx={style}>
            <Grid container sx={{ pl: 2 }} >
              <Typography variant="h4" style={{ color: '#000', margin: "0px 0px 17px 0px" }}>
                Get Active Users
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleCloseActiveUsers}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <FormControl fullWidth style={{ margin: "0px 0px 17px 0px" }}>
                <InputLabel id="demo-simple-select-label">Select company</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedCompany}
                  label="Select month"
                  onChange={handleCompanyChange}
                >
                  {company.map((company, index) => (
                    <MenuItem value={company}>{company}</MenuItem>
                  ))}

                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select month</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedMonth}
                  label="Select month"
                  onChange={handleMonthChange}
                >
                  {months.map((month, index) => (
                    <MenuItem value={index}>{month}</MenuItem>
                  ))}

                </Select>
              </FormControl>
              {activeUsers.length > 0
                ?
                <CSVLink
                  filename={`Active users for ${selectedCompany} - ${months[selectedMonth]}.csv`}
                  data={getCsvData()}
                  style={{
                    display: 'flex',
                    width: '100%',
                    margin: '17px 0px 0px 100px'
                  }}
                >
                  <GetAppIcon sx={{ mr: 1 }} /> <Typography variant="p">Export</Typography>
                </CSVLink>
                :
                <Button variant="contained" color="success" onClick={handleExtractUsers} sx={{ mt: 2, px: 17 }} >
                  {activeUserLoading ? <CircularProgress size={35} style={{ width: '100px' }} color="inherit" /> : 'Export'}
                </Button>
              }

            </Grid>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openChecklist}
        onClose={handleCloseChecklist}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >

        <Fade in={openChecklist}>
          <Box sx={style}>
            <Grid container sx={{ pl: 2 }} >
              <Typography variant="h4" style={{ color: '#000', margin: "0px 0px 17px 0px" }}>
                Checklists
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleCloseChecklist}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <FormControl fullWidth>
                <FormControlLabel sx={{mb: 3}}
                  control={
                    <Checkbox
                      checked={checklistStatus}
                      onChange={(e) => setChecklistStatus(e.target.checked)}
                      color="primary"

                    />
                  }
                  label="Enable Checklists" // Text next to the checkbox
                />
                {checklists.length > 0 ?
                  checklists.map((checklist, key) => (
                    <React.Fragment key={key}>
                        <TextField
                            id="outlined-controlled"
                            label={"Checklist" + (key + 1)}
                            value={checklist}
                            onChange={(e) => {
                                const updatedChecklists = [...checklists];
                                updatedChecklists[key] = e.target.value;
                                setChecklists(updatedChecklists);
                            }}
                        />
                        <br/>
                    </React.Fragment>   
                  ))
                  : null
                }
              </FormControl>
              <Button variant="contained" color="success" onClick={handleSubmitChecklist} sx={{ mt: 2, px: 17 }} >
                {activeUserLoading ? <CircularProgress size={35} style={{ width: '100px' }} color="inherit" /> : 'Submit'}
              </Button>
            </Grid>
          </Box>
        </Fade>
      </Modal>
      <Dialog 
        open={openVideoGuides} 
        onClose={handleCloseVideoGuides} 
        fullWidth
      >
        <DialogTitle>Video Guideline </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseVideoGuides}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{padding: '30px 15px', height:'50vh'}}>
          {videoFetched? videoList && videoList.map((data) =>(
            <Box sx={{
              display:'flex', 
              justifyContent:'space-between',
              alignItems:'center',
              padding: '16px', // Add padding for better spacing
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add a subtle box shadow for a paper effect
              borderRadius: '8px', // Optional: Add border-radius for rounded corners
            }}>
                <Box>
                <Typography style={{ marginBottom: '-1px', fontSize:'10px', fontWeight:'bold' }}> {data.title}</Typography>
                <Typography style={{fontSize:'10px' }}> {data.description} </Typography>
              </Box>
              <Box sx={{height:'1vh'}}>
                <Button variant='contained' size='small' onClick={() => handleViewYoutube(data.youtubeId)}>
                  <Typography color="white" variant='caption'> Watch </Typography>
                </Button>
              </Box>
            </Box>
          )): ''}
          <Box sx={{
            display:'flex', 
            justifyContent:'space-between',
            alignItems:'center',
            padding: '16px', // Add padding for better spacing
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add a subtle box shadow for a paper effect
            borderRadius: '8px', // Optional: Add border-radius for rounded corners
          }}>
              <Box>
              <Typography style={{ marginBottom: '-1px', fontSize:'10px', fontWeight:'bold' }}> Location Tutorial (IOS) </Typography>
              <Typography style={{fontSize:'10px' }}> IOS Location tutorial </Typography>
            </Box>
            <Box sx={{height:'1vh'}}>
              <Button variant='contained' size='small' onClick={() => handleViewYoutube("bxKNDaG-J9Q")}>
                <Typography color="white" variant='caption'> Watch </Typography>
              </Button>
            </Box>
          </Box>
          <Box sx={{
            display:'flex', 
            justifyContent:'space-between',
            alignItems:'center',
            padding: '16px', // Add padding for better spacing
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add a subtle box shadow for a paper effect
            borderRadius: '8px', // Optional: Add border-radius for rounded corners
          }}>
              <Box>
              <Typography style={{ marginBottom: '-1px', fontSize:'10px', fontWeight:'bold' }}> Location Tutorial (Android) </Typography>
              <Typography style={{fontSize:'10px' }}> Android Location tutorial </Typography>
            </Box>
            <Box sx={{height:'1vh'}}>
              <Button variant='contained' size='small' onClick={() => handleViewYoutube("xxN16yFainM")}>
                <Typography color="white" variant='caption'> Watch </Typography>
              </Button>
            </Box>
          </Box>
          <Box sx={{
            display:'flex', 
            justifyContent:'space-between',
            alignItems:'center',
            padding: '16px', // Add padding for better spacing
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add a subtle box shadow for a paper effect
            borderRadius: '8px', // Optional: Add border-radius for rounded corners
          }}>
              <Box>
              <Typography style={{ marginBottom: '-1px', fontSize:'10px', fontWeight:'bold' }}> Onboarding </Typography>
              <Typography style={{fontSize:'10px' }}> Onboarding tutorial </Typography>
            </Box>
            <Box sx={{height:'1vh'}}>
              <Button variant='contained' size='small' onClick={() => handleViewYoutube("AtGwDkSdrhs")}>
                <Typography color="white" variant='caption'> Watch </Typography>
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openUpdateEmail}
        onClose={handleCloseUpdateEmail}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >

        <Fade in={openUpdateEmail}>
          <Box sx={style}>
            <Grid container sx={{ pl: 2 }} >
              <Typography variant="h4" style={{ color: '#000', margin: "0px 0px 17px 0px" }}>
                { authCheck ? 'Enter your password' : 'Update Email'}
              </Typography>
              <IconButton
                aria-label="close"
                onClick={ authCheck ? () => setAuthCheck(false) : () => handleCloseUpdateEmail()}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              {
                authCheck ? (
                <>
                <FormControl fullWidth>
                    <TextField
                        id="outlined-controlled"
                        label={"Enter your password"}
                        type="password"
                        value={authCheckPass}
                        onChange={(e) => {
                          setAuthCheckPass(e.target.value);
                        }}
                    />
                    <br/>
                </FormControl>
                </>
                ) : (
                  <FormControl fullWidth>
                    <React.Fragment key={userState._id}>
                        <TextField
                            id="outlined-controlled"
                            label={"Enter new email"}
                            value={newEmail}
                            onChange={(e) => {
                              setNewEmail(e.target.value);
                            }}
                        />
                        <br/>
                    </React.Fragment>   
                  </FormControl>
                )
              }
              
              <Button variant="contained" color="success" onClick={ authCheck ? () => handleAuthUpdateEmail() : () => setAuthCheck(true)} sx={{ mt: 2, px: 17 }} >
                {submitting ? <CircularProgress size={35} style={{ width: '100px' }} color="inherit" /> : 'Submit'}
              </Button>
            </Grid>
          </Box>
        </Fade>
      </Modal>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={historyLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={scheduleLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={payslipLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}