import './utils/highlight'; // Highlight
import 'simplebar/src/simplebar.css'; // Scrollbar
import 'react-image-lightbox/style.css'; // Lightbox
import 'react-quill/dist/quill.snow.css'; // Rich Text Editors
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'; // Rich Text Editors
import 'slick-carousel/slick/slick.css'; // Carousel
import 'slick-carousel/slick/slick-theme.css'; // Carousel
import '_style.css'; // Global Styles

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { SettingsProvider } from 'utils/context/settings';
import { CollapseDrawerProvider } from 'utils/context/drawer';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import storage from 'utils/storage';

const USERSNAP_GLOBAL_API_KEY = "70ccdab5-9822-416e-b752-ef1d60667f77";

const AppWithTawk = () => {
  const [formattedUser, setFormattedUser] = useState(null);
  const [isIOS, setIsIOS] = useState(/iPhone|iPad|iPod/i.test(navigator.userAgent));

  useEffect(() => {
    setIsIOS(/iPhone|iPad|iPod/i.test(navigator.userAgent));
    if (isIOS && !sessionStorage.getItem("iosReloaded")) {
      sessionStorage.setItem("iosReloaded", "true");
      window.location.reload();
    }
    const fetchData = async () => {
      
      const token = await storage.getToken();
      const user = await storage.getUser();

      if (user) {
        const userObj = JSON.parse(user);
        setFormattedUser(userObj);
        let widgetUrl;
        if (userObj.company.includes("Seven Star Jasem")) {
          widgetUrl = 'https://embed.tawk.to/677c886649e2fd8dfe037085/1igv79kcb';
        } else if (userObj.company.includes("7-Star")) {
          widgetUrl = 'https://embed.tawk.to/677c884349e2fd8dfe037082/1igv78ija';
        } else if (userObj.company.includes("Syzygy")) {
          widgetUrl = 'https://embed.tawk.to/677c88f5af5bfec1dbe79572/1igv7e05k';
        } else {
          widgetUrl = 'https://embed.tawk.to/666a5148981b6c56477c93f6/1i07kr15t';
        }

        if (widgetUrl) {
          const script = document.createElement('script');
          script.async = true;
          script.src = widgetUrl;
          script.charset = 'UTF-8';
          script.setAttribute('crossorigin', '*');
          document.body.appendChild(script);
        }
      }
    };

    fetchData();
  }, []);
  
  return (
    <HelmetProvider>
      <Helmet>
        <title>My App</title>
      </Helmet>
      <SettingsProvider>
        <CollapseDrawerProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </CollapseDrawerProvider>
      </SettingsProvider>
    </HelmetProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <AppWithTawk />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
reportWebVitals();
