import React, {useState, memo, useCallback, useEffect, useRef} from 'react'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'

const BreaklistRemarks = ({id = '', remarks = 'No remarks yet.', onSaveRemark = () => {}}) => {
  let [editable, setEditable] = useState(false)
  let [remark, setRemark] = useState(remarks)
  let remarkEditor = useRef()

  useEffect(() => {
    let mounted = true
    let el = remarkEditor.current

    if (mounted) {
      if (editable) {
        el.addEventListener('keydown', onEditingDone)
        el.focus()
      }
      else el?.removeEventListener('keydown', onEditingDone)
    }

    return () => {
      mounted = false

      el?.removeEventListener('keydown', onEditingDone)
    }
  }, [editable])

  let saveRemark = useCallback(async () => {
    let newRemark = remarkEditor.current.innerText

    if(newRemark.trim().length  >  0) {

      setRemark(newRemark)
      onSaveRemark(id, newRemark)
    }
    else {
      setRemark('No remarks yet.')
    }
  }, [])

  let onEditingDone = useCallback((e) => {
    if (e.which === 13 || e.which === 27) {
      saveRemark()
      turnOffEditable()
    }
  }, [])

  let turnOnEditable = useCallback(() => {
    console.log('setting editable')
    setEditable(true)
  }, [])

  let turnOffEditable = useCallback(() => {
    console.log('not setting editable')
    setEditable(false)
  }, [])

  return (
    <>
      {editable ? (
        <ClickAwayListener
          onClickAway={() => {
            saveRemark()
            turnOffEditable()
          }}
        >
          <div className="breaklist-remark" ref={remarkEditor} contentEditable={editable}>
            {remark}
          </div>
        </ClickAwayListener>
      ) : (
        <div className="breaklist-remark" ref={remarkEditor} onClick={turnOnEditable} contentEditable={editable}>
          {remark}
        </div>
      )}
    </>
  )
}

export default BreaklistRemarks
