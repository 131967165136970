import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CloseIcon from '@mui/icons-material/Close';
import {IconButton, FormControlLabel, Checkbox} from '@material-ui/core'
import Box from '@mui/material/Box';

const NewFeatureDialog = () => {
  const [open, setOpen] = useState(false);
  const [dontShowToday, setDontShowToday] = useState(false);

  useEffect(() => {
    // Check if the dialog should be shown today
    const checkDialogDisplay = () => {
      const currentDate = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format
      const lastShownDate = localStorage.getItem('newFeatureDialogLastShown');
      
      if (lastShownDate === currentDate) {
        // Don't show dialog if user chose not to see it today
        setOpen(false);
      } else {
        setOpen(true);
      }
    };
    
    checkDialogDisplay();
  }, []);

  const handleClose = () => {
    setOpen(false);
    
    // If "Don't show today" is checked, save the current date to localStorage
    if (dontShowToday) {
      const currentDate = new Date().toISOString().split('T')[0];
      localStorage.setItem('newFeatureDialogLastShown', currentDate);
    }
  };

  const handleDontShowTodayChange = (event) => {
    setDontShowToday(event.target.checked);
  };

  return (
    <>
      <Dialog disableScrollLock={true} open={open} onClose={handleClose} >
        <AppBar sx={{ position: "relative" }}>
          <DialogTitle id="alert-dialog-title">Updates
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
          </DialogTitle>
        </AppBar>
        <DialogContent>
          <img
            width="100%"
            src="https://oheast2-upload-s3.s3.us-east-2.amazonaws.com/1740362998569undefined"
            alt="Safari Instructions"
            style={{ borderRadius: "8px", marginBottom: "1em" }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={dontShowToday}
                  onChange={handleDontShowTodayChange}
                  color="primary"
                />
              }
              label="Don't show today"
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NewFeatureDialog;