import React from 'react'
import Box from '@material-ui/core/Box'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import Button from '@material-ui/core/Button'
import { Icon } from '@iconify/react'
import closeIcon from '@iconify/icons-eva/close-circle-outline'
import Table from './table'
import Typography from '@material-ui/core/Typography'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'

const UserBreaklistModal = ({breaklistId = '', breaklist = [], onClose = () => {}, onSave = () => {}, name = ''}) => {
	return (
		<Modal 
		  aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      open={true}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
     	
		>
			<div>
				<Fade in={true}>
			    <Box
			      sx={{
			        position: 'absolute',
			        top: '50%',
			        left: '50%',
			        transform: 'translate(-50%, -50%)',
			        width: '90%', 
			        maxHeight: '80vh', // Limits the modal's height to 80% of the viewport
			        overflowY: 'auto', // Enables vertical scrolling
			        bgcolor: 'background.paper',
			        boxShadow: 24,
			        p: 4,
			        borderRadius: 2,
			      }}
			    >	
					<Button sx={{ position: 'absolute', right: 1, top: 12, zIndex: 10 }} onClick={onClose}>
			      		<Icon icon={closeIcon} width={25} height={25} color="#b2b2b2" />
			    	</Button>
			    	<Box> {name}</Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2, mb: 2 }}>
              <Typography>Highlighted values are editable by clicking on it.</Typography>
              <Button 
                variant="contained" 
                color="primary" 
                size="small"
                onClick={onSave}
              >
                Refresh Changes
              </Button>
            </Box>
			    	<Table breaklistId={breaklistId} breaklist={breaklist} onSave={onSave} />
			    </Box>
			  </Fade>
			</div>
		</Modal>
	)	
}

export default UserBreaklistModal
