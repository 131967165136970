// material
import { Box, Button, Typography } from '@material-ui/core';
import BreakInTrue from '../../assets/svg/breakTime1.png';
import TimeInOut from '../../assets/svg/tIntOut.png';
import TimeInOutUnable from '../../assets/svg/unabletimeInOut.png'
export default function BreakSwitch({ current_status, handleSwitchTimeBreak, disabled }) {
  return (
    <Button
      fullWidth
      size="medium"
      sx={{
        borderRadius: 28, 
        backgroundColor: current_status === 'Break in' ? '#878B8B' : '#1F98E8',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.35)!important',
        color:'white!important',
        width:'150px',
        // cursor: 'pointer', // Adding cursor: 'pointer' to indicate that it's clickable
        cursor: current_status === 'Time in' || current_status === 'Break out' ? 'pointer' : 'default', // Conditionally setting cursor style
      }}
      onClick={() => {
        if (current_status === 'Break out') {
          handleSwitchTimeBreak(false);
        } else {
          handleSwitchTimeBreak(true);
        }
      }}
      disabled={disabled || false}
    >
      {current_status === 'Break in' || current_status === 'Break out' ? (
        'Time In/Out'
      ) : (
        'Break Time'
      )}
    </Button>
  );
}
