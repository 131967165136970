import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  Typography,
  Modal,
  IconButton,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import OTPInput from 'react-otp-input';
import Numpad from 'components/authentication/login/Numpad';
import FourDigitDisplay from 'components/authentication/login/FourDigitDisplay';
import useUser from 'utils/api/users';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function AccountChangeMPIN({ open, onClose, user, phone }) {
  const [MPINStep, setMPINStep] = useState('input');
  const [MPIN, setMPIN] = useState('');
  const [confirmMPIN, setConfirmMPIN] = useState('');
  const [showMPIN, setShowMPIN] = useState(false);
  const [otp, setOtp] = useState('');
  const [countdown, setCountdown] = useState(0);
  const intervalRef = useRef(null);

  // Handle countdown timer for OTP resend
  React.useEffect(() => {
    if (countdown > 0) {
      intervalRef.current = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);
      return () => clearInterval(intervalRef.current);
    }
  }, [countdown]);

  const handleMPINChange = (value) => {
    switch (MPINStep) {
      case 'input':
        setMPIN(value);
        break;
      case 'confirm':
        setConfirmMPIN(value);
        break;
      default:
        console.log('Unknown operation type');
        break;
    }
  };

  const handleMPINClear = () => {
    switch (MPINStep) {
      case 'input':
        setMPIN("");
        break;
      case 'confirm':
        setConfirmMPIN("");
        break;
      default:
        console.log('Unknown operation type');
        break;
    }
  };

  const handleSendMPINChangeOtp = async () => {
    setCountdown(10);
    try {
        let data
        if (phone) {
             data = {
                phone: phone,
            }
        } else {
          console.log('check data: ', user)
             data = {
                phone: user.phone,
                email: user.email
            }
        }
      if (countdown === 0) {
        await useUser.send_MPIN_change_otp(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMPINEnter = async () => {
    let data
    switch (MPINStep) {
      case 'input':
        if (!MPIN || MPIN.length < 4) {
          alert('Please enter a 4-digit MPIN');
          return;
        }
        setMPINStep('confirm');
        break;
      case 'confirm':
        if (!confirmMPIN || confirmMPIN.length < 4) {
          alert('Please enter a 4-digit MPIN');
          return;
        }
        if (MPIN !== confirmMPIN) {
          alert('MPINs do not match');
          break;
        }
        handleSendMPINChangeOtp();
        setMPINStep('otp');
        break;
      case 'otp':
        try {
          if (otp === '') {
            alert("Please enter OTP");
            return;
          }
          if (MPIN === "") {
            alert("Please enter MPIN");
            return;
          }
          
          if (MPIN.length < 4) {
            alert("MPIN must be at least 4 digits");
            return;
          }
          if(phone) {   
            data = {
                phone: phone,
                mpin: MPIN,
                otp: otp
            }
          } else {
            data = {
                id: user._id,
                mpin: MPIN,
                otp: otp,
                phone: user.phone
            };
          }
          const result = await useUser.set_MPIN(data);
          if (!result.data.success) {
            alert('Failed to set MPIN');
            return;
          }
          alert('MPIN set successfully');
          handleClose();
        } catch (error) {
          console.error('An error occurred:', error);
          alert('Failed to set MPIN');
        }
        break;
      default:
        console.log('Unknown operation type');
        break;
    }
  };

  const handleClose = () => {
    setMPIN("");
    setConfirmMPIN("");
    setMPINStep('input');
    setOtp("");
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        {MPINStep === 'otp' ? (
          <>
            <Typography variant="h6" component="h2">
              Enter OTP
            </Typography>
            <Typography variant="body2" sx={{ mt: 1, mb: 2 }}>
              Please enter the OTP sent to your registered email.
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2, width: "100%" }}>
              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                inputType="number"
                shouldAutoFocus
                containerStyle={{ display: "flex", gap: "8px" }}
                inputStyle={{
                  width: "3rem",
                  height: "3rem",
                  fontSize: "1.5rem",
                  borderRadius: "8px",
                  border: "1px solid #ccc",
                  textAlign: "center",
                  outline: "none",
                  background: "#fff",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  transition: "border-color 0.2s",
                }}
                renderInput={(props) => <input {...props} />}
              />
            </Box>
            <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
              {countdown > 0 ? (
                <Typography variant="body2" color="error">
                  Please wait {countdown} seconds before sending another OTP
                </Typography>
              ) : (
                <Button variant="contained" color="primary" onClick={handleSendMPINChangeOtp}>
                  Resend OTP
                </Button>
              )}
            </Box>
          </>
        ) : (
          <>
            <Typography variant="h6" component="h2">
              {MPINStep === 'confirm' ? 'Confirm MPIN' : 'Set New MPIN'} 
            </Typography>
            <Box mt={4} display={'flex'} width={'100%'} sx={{mb: 2}}>
              <FourDigitDisplay value={MPINStep === 'confirm' ? confirmMPIN : MPIN} type={showMPIN ? 'set' : 'enter'} sx={{flexGrow: 1}}/>
            </Box>
            <Box display={'flex'} justifyContent={'center'} width={'100%'} sx={{mb: 1}}>
              <IconButton onClick={() => setShowMPIN(prev => !prev)} >
                { showMPIN ? <VisibilityOff /> : <Visibility /> }
              </IconButton>
            </Box>
            
            <Numpad
              onChange={(value) => handleMPINChange(value)}
              onClear={handleMPINClear}
              initialValue={MPINStep === 'confirm' ? confirmMPIN : MPIN}
            />
          </>
        )}
        
        <Box mt={2} display="flex" width={'100%'} justifyContent="space-between">
          <Button variant="outlined" color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="success" onClick={handleMPINEnter}>
            {MPINStep === 'otp' ? 'Submit' : 'Next'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
