import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, IconButton, Typography } from '@material-ui/core'
import { Icon } from '@iconify/react';

const Numpad = ({ onChange, onEnter, onClear, initialValue = '' }) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (value.length === 4) {
      handleEnter();
    }
    setValue(initialValue);
  }, [value, initialValue]);

  // Append the pressed digit to the current value
  const handleButtonClick = (digit) => {
    const newValue = value + digit;
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  // Clear the current value
  const handleClear = () => {
    setValue('');
    if (onClear) {
      onClear();
    }
  };

  // Trigger the enter callback
  const handleEnter = () => {
    if (onEnter) {
      onEnter(value);
    }
  };

  return (
    <Box sx={{ width: '100%', margin: '0 auto', p: 2 }}>
      <Grid container spacing={1}>
        {/* Row 1: 1, 2, 3 */}
        <Grid item xs={4}>
          <Button fullWidth variant="contained" onClick={() => handleButtonClick('1')}>
            1
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button fullWidth variant="contained" onClick={() => handleButtonClick('2')}>
            2
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button fullWidth variant="contained" onClick={() => handleButtonClick('3')}>
            3
          </Button>
        </Grid>
  
        {/* Row 2: 4, 5, 6 */}
        <Grid item xs={4}>
          <Button fullWidth variant="contained" onClick={() => handleButtonClick('4')}>
            4
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button fullWidth variant="contained" onClick={() => handleButtonClick('5')}>
            5
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button fullWidth variant="contained" onClick={() => handleButtonClick('6')}>
            6
          </Button>
        </Grid>
  
        {/* Row 3: 7, 8, 9 */}
        <Grid item xs={4}>
          <Button fullWidth variant="contained" onClick={() => handleButtonClick('7')}>
            7
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button fullWidth variant="contained" onClick={() => handleButtonClick('8')}>
            8
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button fullWidth variant="contained" onClick={() => handleButtonClick('9')}>
            9
          </Button>
        </Grid>
  
        {/* Row 4: Clear, 0, Backspace */}
        <Grid item xs={4}>
          <Button fullWidth variant="outlined" color="secondary" onClick={handleClear}>
            Clear
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button fullWidth variant="contained" onClick={() => handleButtonClick('0')}>
            0
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={() => {
              const newValue = value.slice(0, -1);
              setValue(newValue);
              if (onChange) {
                onChange(newValue);
              }
            }}
            style={{ borderRadius: '12px' }}
          >
            <Icon icon="material-symbols:backspace-rounded" style={{ fontSize: 25 }} />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
  
};

export default Numpad;
